@font-face {
	font-family: 'JalnanOTF';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('../font/JalnanOTF.woff2') format('woff2'), url('../font/JalnanOTF.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: local('Pretendard Black'), url('../font/Pretendard-Black.woff2') format('woff2'), url('../font/Pretendard-Black.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: local('Pretendard ExtraBold'), url('../font/Pretendard-ExtraBold.woff2') format('woff2'), url('../font/Pretendard-ExtraBold.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local('Pretendard Bold'), url('../font/Pretendard-Bold.woff2') format('woff2'), url('../font/Pretendard-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: local('Pretendard SemiBold'), url('../font/Pretendard-SemiBold.woff2') format('woff2'), url('../font/Pretendard-SemiBold.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-style: bold;
	font-weight: 500;
	font-display: swap;
	src: local('Pretendard Medium'), url('../font/Pretendard-Medium.woff2') format('woff2'), url('../font/Pretendard-Medium.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Pretendard Regular'), url('../font/Pretendard-Regular.woff2') format('woff2'), url('../font/Pretendard-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: local('Pretendard Light'), url('../font/Pretendard-Light.woff2') format('woff2'), url('../font/Pretendard-Light.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: local('Pretendard ExtraLight'), url('../font/Pretendard-ExtraLight.woff2') format('woff2'), url('../font/Pretendard-ExtraLight.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: local('Pretendard Thin'), url('../font/Pretendard-Thin.woff2') format('woff2'), url('../font/Pretendard-Thin.woff') format('woff');
}