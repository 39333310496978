.footer {
    position: relative;
    width: 100%;
    padding: 48rem 24rem 64rem;
    background-color: $--clr-gray-3;
    z-index: 6;
    & > * {
        width: 100%;
        max-width: 1024px;
        margin: 0 auto;
    }
    .for_header {
        display: none;
    }
    .flex {
        position: relative;
        display: flex;
        &.vert {
            flex-direction: column;
        }
        &.top {
            & > .flex:first-child {
                max-width: 444px;
            }
        }
        &.mid {
            width: 100%;
            margin-top: 42px;
            .logo_img {
                display: block;
                background-size: contain;
                background-repeat: no-repeat;
                &.if {
                    width: 122px;
                    height: 60px;
                    background-image: var(--logo-ifaward);
                    background-position: right center;
                }
            }
        }
        &.btm {
            width: 100%;
            margin-top: 30px;
            padding-top: 0;
        }
    }
    &__inner {
        * {
            font-size: 12rem;
            font-weight: 400;
            line-height: 1.5;
            color: $--clr-white;
        }
        .sub-menu {
            .sub-item {
                & > a {
                    &:not(:last-child) {
                        display: inline-block;
                        margin-bottom: 12rem;
                    }
                    span {
                        font-weight: 700;
                        color: $--clr-gray-3;
                    }
                }
                & + .sub-item {
                    margin-top: 12rem;
                    & > a {
                        display: inline-block;
                        margin-top: 6rem;
                    }
                }
                .child-menu {
                    .child-item {
                        & + .child-item {
                            margin-top: 12rem;
                        }
                    }
                }
                a, span {
                    font-family: 'Pretendard', sans-serif;
                    font-size: 14rem;
                    font-weight: 400;
                    color: $--clr-gray-2;
                }
            }
        }
        .ft_logo {
            display: block;
            .logo_img {
                display: block;
                width: 144px;
                height: 28px;
                background-image: var(--logo-incheon-g);
                background-size: contain;
                background-position: left center;
                background-repeat: no-repeat;
            }
            &:not(:last-child) {
                margin-right: 24px;
            }
            & ~ .select-wrap {
                position: relative;
                display: block;
                width: 140px;
                height: 32px;
                padding: 7px 34px 7px 16px;
                background-color: $--clr-black-trans;
                border: 1px solid $--clr-gray-5;
                border-radius: 8px;
                font-size: 12px;
                font-weight: 400;
                line-height: 1.5;
                color: $--clr-lightgray-1;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                text-indent: 1px;
                text-overflow: '';
                background-image: var(--icon-arr-dn-w);
                background-size: 10px 10px;
                background-position: right 10px center;
                background-repeat: no-repeat;
                cursor: pointer;
                option {
                    padding: 7px 16px;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 1.5;
                    color: $--clr-gray-5;
                    &:disabled, &[disabled] {
                        display: none;
                    }
                    &:hover {
                        background: $--clr-point-1;
                    }
                }
                &:focus, &:hover {
                    outline: none;
                    box-shadow: none;
                    border: 1px solid $--clr-point-1;
                }
                &:last-child {
                    margin-left: auto;
                }
            }
        }
        .logo_text {
            display: flex;
            flex-direction: column;
            height: 100%;
            .comm_info {
                display: block;
                p, a {
                    word-break: keep-all;
                    color: $--clr-lightgray-1;
                }
                div {
                    display: block;
                    p, a {
                        display: inline-block;
                        &:not(:first-child) {
                            margin-left: 0;
                        }
                    }
                }
                p, a, div {
                    & + p, & + a, & + div {
                        margin-top: 0;
                    }
                }
            }
            .agree_info {
                display: inline-block;
                &:not(:first-child) {
                    margin-top: auto;
                }
                &:not(:last-child) {
                    margin-bottom: 8px;
                }
                & > *:not(:first-child) {
                    margin-left: 10px;
                }
                & > div[data-for="mo"]{
                    margin-top: 12px;
                    & > a {
                        color: $--clr-white;
                    }
                }
                p, a, .popupLink {
                    display: inline-block;
                    width: auto;
                    word-break: keep-all;
                    font-size: 12rem;
                    font-weight: 400;
                    &.with_line {
                        &::before {
                            content: '';
                            display: inline-block;
                            width: 1px;
                            height: 12px;
                            margin: 0 10px;
                            background-color: $--clr-gray-5;
                            pointer-events: none;
                        }
                    }
                }
                strong {
                    font-weight: 700;
                }
            }
        }
        .icon {
            &-shortcut {
                display: inline-flex !important;
                align-items: center;
                width: auto;
                height: auto;
                font-weight: 700;
                & + .icon-shortcut {
                    margin-left: 12rem !important;
                }
                &::after {
                    content: '';
                    display: inline-block;
                    width: 14px;
                    min-width: 14px;
                    height: 14px;
                    min-height: 14px;
                    margin-left: 0;
                    background-image: var(--icon-shortcut);
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
        .with_shortcut {
            display: flex;
            align-items: center;
            &:after {
                content: '';
                display: inline-block;
                width: 11px;
                height: 11px;
                margin-left: 4px;
                background-image: var(--icon-shortcut-l-g);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        .copy_right {
            display: block;
            text-align: left;
            font-size: 10px;
            font-weight: 400;
            line-height: 1.8;
            color: $--clr-lightgray-1;
            &:not(:first-child) {
                margin-top: 4px !important;
            }
        }
        .select-wrap {
            display: inline-flex;
            flex-direction: column;
            width: auto;
            height: 32px;
            overflow: visible;
            .btn {
                &-input {
                    display: none;
                    position: absolute;
                    width: 0;
                    height: 0;
                    &:checked {
                        & + .btn-select {
                            &::after {
                                background-image: var(--icon-arr-dn-16-b);
                            }
                        }
                        & ~ .option-list {
                            max-height: 800px;
                            opacity: 1;
                            pointer-events: auto;
                        }
                    }
                }
                &-select {
                    display: inline-flex;
                    justify-content: space-between;
                    min-width: 167px;
                    min-height: 32px;
                    padding: 0;
                    background-color: $--clr-lightgray-2;
                    border-radius: 32px;
                    font-weight: 600;
                    outline: 0;
                    border: 0;
                    word-break: keep-all;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    cursor: pointer;
                    &::after {
                        content: '';
                        display: inline-block;
                        width: 16px;
                        min-width: 16px;
                        height: 16px;
                        margin-left: 0;
                        background-image: var(--icon-arr-up-16-b);
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
            }
            .option-list {
                position: absolute;
                display: block;
                width: inherit;
                min-width: 167px;
                height: auto;
                max-height: 0;
                padding: 0;
                transform: translate(0, calc(-100% - 8px));
                background-color: $--clr-lightgray-2;
                border-radius: 8px;
                opacity: 0;
                overflow: hidden;
                pointer-events: none;
                transition: max-height 0.5s ease-in-out;
                .btn {
                    &-option {
                        width: 100%;
                        height: auto;
                        padding: 0;
                        word-break: keep-all;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        & > a {
                            display: inline-block;
                            width: 100%;
                        }
                        &:not(:first-child) {
                            border-top: 1px solid $--clr-gray-5;
                        }
                    }
                }
            }
        }
    }
}

// PC SET
@media screen and (min-width: 1024px) {
    .footer {
        .menu_mo, .hamburger-menu {
            display: none !important;
        }
        &__inner {
            div[data-for='mo']{
                display: none !important;
            }
        }
    }
}

// MO SET
@media screen and (max-width: 1023px) {
    .footer {
        padding: 40rem 16rem 64rem !important;
        &__inner {
            * {
                font-size: 12rem;
            }
            .flex {
                width: 100%;
                flex-direction: column;
                flex-wrap: wrap;
                .ft_logo {
                    flex: 1 1 40%;
                    display: inline-block;
                    width: 50%;
                    min-height: 32rem;
                    margin: 0;
                    .logo_img {
                        width: 120rem;
                        height: 24rem;
                    }
                }
                .select-wrap {
                    flex: 1 1 40%;
                    order: 1;
                    position: absolute;
                    display: inline-block;
                    width: 50%;
                    max-width: 140rem;
                    right: 0;
                    margin: 0;
                    &:last-child {
                        margin-left: 0;
                    }
                }
                .logo_text {
                    order: 2;
                    flex: 1 1 40%;
                    margin-top: 16rem;
                }
            }
        }
    }
}