html {
	font-size: 1px;
	&.hidden {
		overflow: hidden !important;
		body {
			overflow: hidden !important;
		}
	}
}
body {
	#header{
    }
	.header {
		position: sticky;
		display: flex;
		max-height: 80px;
		top: 0;
		padding: 0 24rem;
		z-index: 9;
		transition: all 0.5s ease-in-out;
		background-color: $--clr-white;
		border-bottom: 1px solid $--clr-black-trans;
		box-shadow: 0 1px 2px rgba(0,0,0,0);
		&.fixed {
			position: fixed;
			width: 100%;
		}
		&.onmouseOver {
			background-color: rgba(255,255,255,0.95);
			backdrop-filter: blur(4px);
		}
		&.onScroll {
			box-shadow: 0 1px 2px rgba(0,0,0,0.08);
			border-bottom: 1px solid $--clr-lightgray-3;
		}
		&__inner {
			position: relative;
			display: inline-flex;
			width: 100%;
			max-width: 1024px;
			min-height: 80px;
			height: 100%;
			max-height: 80px;
			margin: 0 auto;
			//padding: 0 200px;
			& > * {
				margin: auto 0;
			}
			&-bg {
				position: fixed;
				width: 100%;
				height: 100%;
				max-height: 80px;
				top: 0;
				left: 0;
				z-index: -1;
				opacity: 0;
				background-color: $--clr-white;
				transition: all 0.1s 0.1s ease-in-out;
				pointer-events: none;
				&::after {
					position: absolute;
					display: inline-block;
					content: '';
					width: 100%;
					height: 1px;
					top: 80px;
					background-color: $--clr-lightgray-3;
					opacity: 0;
					transition: opacity 0.1s linear;
					pointer-events: none;
				}
				&.active {
					height: 328px;
					max-height: 328px;
					background-color: rgba(255,255,255,0.95);
					backdrop-filter: blur(4px);
					box-shadow: 0 1px 2px rgba(0,0,0,0.08);
					opacity: 1;
					transition: all 0.3s ease-in-out;
					pointer-events: auto;
					&::after {
						opacity: 0;
						transition: opacity 0.3s linear;
					}
				}
			}
			.logo {
				&-main {
					display: block;
					width: 100%;
					max-width: 112px;
					min-height: 40px;
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					background-image: var(--logo-incheon);
				}
			}
			.menu {
				display: flex;
				align-self: center;
				width: auto;
				height: 80px;
				margin: 0 0 0 auto;
				z-index: 5;
				&-container {
					display: flex;
					width: auto;
					height: auto;
				}
				&-item {
					display: flex;
					width: auto;
					height: auto;
					& > a {
						display: flex;
						align-items: center;
						height: 100%;
						padding: 0 28rem;
						font-size: 14rem;
						font-weight: 700;
						color: $--clr-black;
						cursor: default;
						&[href] {
							transition: color 0.15s ease-in-out;
							&:hover {
								color: $--clr-point-1;
							}
						}
						& > span {
							pointer-events: none;
						}
					}
					&:first-child {
						margin-left: 0;
						& > a {
							padding-left: 0;
						}
					}
					&:last-child {
						margin-right: 0;
						& > a {
							padding-right: 0;
						}
					}
				}
			}
			.menu_mo {
				display: block;
				position: fixed;
				width: 100%;
				height: 100%;
				margin: 0;
				padding-top: 60px;
				top: 0;
				left: 0;
				background-color: $--clr-white;
				z-index: -99;
				pointer-events: none;
				opacity: 0;
				transition: opacity 0.3s ease-in-out;
				li, .for_header {
					pointer-events: none !important;
				}
				a {
					pointer-events: none !important;
					cursor: default !important;
				}
				&.open {
					opacity: 1;
					z-index: -1;
					pointer-events: auto;
					transition: opacity 0.3s ease-in-out;
					li, .for_header {
						pointer-events: auto !important;
					}
					a {
						pointer-events: auto !important;
						cursor: pointer !important;
					}
				}
				.menu {
					&-container {
						flex-direction: column;
						max-height: 100%;
						padding: 19px 24px;
						overflow-y: auto;
					}
					&-item {
						margin: 0;
						padding: 0;
						flex-direction: column;
						& > a {
							display: flex;
							align-items: center;
							width: 100%;
							padding: 19px 0;
							cursor: pointer;
							&::after {
								content: '';
								display: inline-block;
								width: 16px;
								height: 9px;
								margin-left: auto;
								background-image: var(--icon-arr-dn-b);
								background-size: contain;
								background-position: center;
								background-repeat: no-repeat;
							}
							&[href] {
								&::after {
									display: none;
								}
								& + .sub-menu {
									display: none;
								}
								&.active {
									& + .sub-menu {
										display: none;
										max-height: 0;
										opacity: 0;
									}
								}
							}
							&.active {
								color: $--clr-point-1;
								&::after {
									background-image: var(--icon-arr-up-b);
								}
								& + .sub-menu {
									position: relative;
									display: block;
									width: calc(100% + 48px);
									max-width: unset;
									height: auto;
									max-height: 1000px;
									margin-left: -24px;
									padding: 14px 40px;
									opacity: 1;
									transition: max-height 0.3s ease-in-out;
									//background-color: $--clr-lightgray-3;
									z-index: 5;
									& > .sub-item {
										margin: 0;
										padding: 0;
										&:not(:first-child) {
											margin-top: 30px;
										}
										& > a {
											display: block;
											width: 100%;
											margin: 0;
											font-size: 14rem;
											font-weight: 800;
											line-height: 1.44;
											cursor: pointer;
											pointer-events: auto;
											& + .child-menu {
												padding-left: 16rem;
											}
										}
										&:first-child:last-child {
											.child-menu {
												&:first-child {
													flex-direction: column;
												}
												.child-item {
													&:first-child{
														& > a {
															padding: 0 0 12rem;
														}
													}
													&:last-child{
														& > a {
															padding: 12rem 0 0;
														}
													}
													& > a {
														font-weight: 400;
														padding: 12rem 0;
													}
												}
											}
										}
										.child-menu {
											.child-item {
												& > a {
													display: block;
													width: 100%;
													margin: 0;
													font-size: 16rem;
													font-weight: 500;
													line-height: normal;
													color: $--clr-gray-4;
													cursor: pointer;
													pointer-events: auto;
												}
											}
										}
									}
								}
							}
						}
						.sub-menu {
							max-height: 0;
							opacity: 0;
							transition: max-height 0.3s ease-in-out;
						}
					}
				}
			}
			.sub-menu {
				display: flex;
				position: fixed;
				//width: 100%;
				height: 0;
				top: 0;
				left: 0;
				transform: translate(0, 80px);
				opacity: 0;
				transition: opacity 0.1s 0.2s ease-in-out;
				z-index: -99;
				overflow: hidden;
				//pointer-events: none;
				&.active {
					height: 224px;
					opacity: 1;
					z-index: 5;
					transition: opacity 0.2s 0.1s ease-in-out;
					overflow: visible;
				}
				.sub-item {
					position: absolute;
					margin-top: 0;
					&:not(:first-child) {
						margin-left: 50px;
					}
					& > a {
						font-size: 13rem;
						font-weight: 400;
						color: $--clr-black;
						margin: 0;
						& > span {
							pointer-events: none;
						}
					}
					&:first-child:last-child {
						.child-menu {
							display: flex;
							//flex-direction: row;
							flex-direction: column;
							.child-item {
								//margin-top: 0;
								&:first-child {
									margin-top: 0;
								}
								//& > a {
								//	font-weight: 500;
								//}
							}
						}
					}
				}
			}
			.child-menu {
				position: relative;
				display: flex;
				flex-direction: column;
				width: auto;
				height: auto;
				top: unset;
				left: unset;
				opacity: 1;
				transition: unset;
				z-index: 0;
				pointer-events: auto;
				.child-item {
					margin-top: 0;
					white-space: nowrap;
					text-align: right;
					transition: color 0.2s ease-in-out;
					& > a {
						font-size: 13rem;
						font-weight: 400;
						line-height: 2.15;
						color: $--clr-black;
						margin: 0;
						transition: color 0.15s ease-in-out;
						& > span {
							pointer-events: none;
						}
					}
					&:hover {
						& > a {
							color: $--clr-point-1;
						}
					}
				}
			}
			.sub_link {
				&-container {
					display: flex;
				}
			}
			.hamburger-menu {
				display: none;
				&:not(:first-child) {
					margin-left: 20px;
				}
				.hamburger {
					width: 24px;
					height: 24px;
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					background-image: var(--btn-hamburger-b);
				}
			}
		}
		&.clr {
			&-b{
				transition: background-color 0.2s ease-in-out;
				.menu {
					&-item {
						& > a {
							color: $--clr-black;
						}
					}
				}
				.lang-menu {
					.lang-link {
						color: $--clr-black;
						&::before {
							background-image: var(--lang-globe-b);
						}
					}
				}
				.hamburger-menu {
					.hamburger {
						background-image: var(--btn-hamburger-b);
					}
				}
			}
			&-bg-fixed {
				transition: background-color 0.2s ease-in-out, top 0.4s linear;
				background-color: $--clr-white !important;
				.menu {
					&-item {
						& > a {
							color: $--clr-black !important;
						}
					}
				}
				.lang-menu {
					.lang-link {
						color: $--clr-black !important;
						&::before {
							background-image: var(--lang-globe-b) !important;
						}
					}
				}
				.hamburger-menu {
					.hamburger {
						background-image: var(--btn-hamburger-b) !important;
					}
				}
			}
		}
	}
}

// PC SET
@media screen and (min-width: 1024px) {
	.header {
		.menu_mo, .hamburger-menu {
			display: none !important;
		}
	}
}

// MO SET
@media screen and (max-width: 1023px) {
	.header {
		max-width: 100vw !important;
		max-height: 60rem !important;
		padding: 0 !important;
		&.hidden {
			top: -60px;
		}
		&__inner {
			width: 100%;
			min-height: 60rem !important;
			max-height: 60rem !important;
			padding: 0 16rem !important;
			&-bg {
				max-height: 60rem !important;
				&::after {
					top: 80rem !important;
				}
			}
			.logo-main {
				background-position: left !important;
			}
			.sub_link {
				&-container{
					align-items: center;
					margin-left: auto;
					.lang-link {
						width: auto !important;
						&::before {
							width: 25rem !important;
							min-width: 25rem !important;
							height: 25rem !important;
							min-height: 25rem !important;
						}
						& > span {
							display: none;
						}
					}
					.hamburger-menu {
						display: block !important;
						.hamburger {
							width: 24rem !important;
						}
					}
				}
			}
			.menu {
				display: none !important;
			}
			.menu_mo {
				padding-top: 60rem !important;
				.menu-item {
					& > a {
						font-size: 18rem;
						font-weight: 700;
						line-height: normal;
					}
					.sub-menu {
						.child-menu {
							.child-item {
								& > a {
									font-size: 16rem;
									font-weight: 500;
									line-height: normal;
								}
							}
						}
					}
				}
				&.open {
					.menu-item {
						& > a:not(.active) {
							color: $--clr-black !important;
						}
						.sub-menu {
							transform: unset;
						}
						.sub-item {
							position: relative;
							&:first-child:last-child {
								.child-menu {
									.child-item {
										text-align: left;
										&:not(:first-child) {
											margin: 0;
										}
									}
								}
							}
						}
					}
					& + .sub_link-container {
						.lang-menu .lang-link::before {
							background-image: var(--lang-globe-b) !important;
						}
						.hamburger-menu .hamburger {
							background-image: var(--btn-hamburger-close) !important;
						}
					}
				}
			}
		}
	}
}