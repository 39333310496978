// MO SET(SMALL)
@media screen and (max-width: 340px) {
    html {
        font-size: 0.8px;
    }
}

// MO SET
@media screen and (max-width: 1023px) {
  .frame {
    &-container {
      &.for_main {
        padding: 0;
        .frame-contents__inner {
          padding: 0 16rem;
          &:first-child {
            margin-bottom: 16rem;
          }
          &.mainMenu {
            & > .flex {
              justify-content: center;
              align-items: center;
              flex-wrap: wrap;
              max-width: 340rem;
              & > div {
                flex: 1 1 25%;
                width: 72rem;
                min-width: 72rem;
                max-width: 72rem;
                min-height: 100rem;
                //&:nth-child(1), &:nth-child(5) {
                //  margin-left: auto;
                //}
                //&:nth-child(4), &:nth-child(8) {
                //  margin-right: auto;
                //}
                &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
                  margin-top: 16rem;
                }
                & > a {
                  padding: 0 8rem;
                  img {
                    width: 56rem;
                    height: 56rem;
                  }
                  p {
                    font-size: 13rem;
                    font-weight: 400;
                    line-height: 1.38;
                    color: $--clr-black;
                    &:not(:first-child) {
                      margin-top: 8px;
                    }
                  }
                }
              }
            }
          }
          &.gridMenu {
            margin-bottom: 64rem;
            & > .flex {
              & > .block_box {
                width: 100%;
                height: auto;
                min-height: 184rem;
                &:nth-child(1) {
                  flex: 1 1 100%;
                  margin: 0;
                  &:not(:last-child) {
                    margin-right: 0;
                  }
                }
                &:nth-child(2) {
                  flex: 1 1 100%;
                  margin: 0;
                  &:not(:first-child) {
                    margin-left: 0;
                  }
                }
                &:not(:nth-child(1)):not(:nth-child(2)) {
                  flex: 1 1 100%;
                  margin-top: 0;
                  &:not(:nth-child(3)):not(:last-child) {
                    margin-left: 0;
                    margin-right: 0;
                  }
                  &:nth-child(3) {
                    margin-right: 0;
                  }
                  &:last-child {
                    margin-left: 0;
                  }
                }
                &:not(:first-child) {
                  margin-top: 20rem !important;
                }
                & > a {
                  &:first-child:last-child {
                    width: 100%;
                    height: 100%;
                    img {
                      position: relative;
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                }
                & > .flex {
                  align-items: center;
                  height: auto;
                  min-height: 84px;
                  & > .img_wrap {
                    width: 64px;
                    min-width: 64px;
                    height: 64px;
                    padding: 0;
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: contain;
                    }
                  }
                  & > .text_wrap {
                    &.with_line {
                      display: flex;
                      align-items: center;
                      &:before {
                        content: '';
                        position: relative;
                        display: inline-block;
                        width: 1px;
                        height: 40px;
                        margin: 0 24px;
                        background-color: $--clr-lightgray-2;
                      }
                    }
                  }
                }
                .box {
                  &-text {
                    font-size: 13rem;
                    font-weight: 400;
                    line-height: normal;
                    color: #375C72;
                  }
                  &-subtitle {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 1.25;
                    color: #000;
                    &:not(:last-child) {
                      margin-bottom: 16px;
                      & + .box-text {
                        margin-top: -12px;
                      }
                    }
                  }
                  &-title {
                    font-size: 28px;
                    font-weight: 500;
                    line-height: normal;
                    color: #313A40;
                  }
                }
                .linkTo {
                  &-icon {
                    img {
                      width: 40px;
                      height: 40px;
                      min-height: 40px;
                    }
                    .box-text {
                      margin-top: 12px;
                      font-size: 11px;
                    }
                  }
                }
              }
              .tab-box {
                display: inline-block;
                width: 100%;
                height: auto;
                .tab {
                  &-wrap {
                    margin-bottom: 18rem;
                    padding: 0;
                  }
                  &-label {
                    font-size: 16rem;
                    font-weight: 700;
                    line-height: 1.25;
                    letter-spacing: -0.48px;
                  }
                }
              }
              .btn {
                &.see-more {
                  span {
                    display: none;
                  }
                  &:before {
                    margin-right: 0;
                  }
                }
              }
              .youtube-link {
                &:after {
                  width: 80rem;
                  height: 56rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .mainSwiper {
    &:before, &:after {
      display: none;
    }
    .text_wrap {
      max-width: 100%;
      max-height: 144rem;
      padding-top: 32rem;
      & > * {
        &:first-child {
          max-width: 100%;
          //font-size: 22rem;
          font-size: min(22rem, 6.11vw);
          font-weight: 700;
          line-height: normal;
        }
        &:not(:first-child), &:not(:first-child) p {
          font-size: 12rem;
          line-height: 1.17;
        }
      }
    }
    .img_wrap {
      height: 208rem;
      border-radius: 16rem;
      img {
        width: 160rem;
        height: 160rem;
        top: -32rem;
        right: 10rem;
      }
    }
    .swiper {
      &-wrapper {
        height: 238rem;
      }
      &-slide {
        padding: 32rem 30rem;
        //&:not(.swiper-slide-active), &-prev, &-next {
        //  mask-image: linear-gradient(0deg, $--clr-white-trans 0%, $--clr-white-trans 100%);
        //}
      }
      &-button {
        &-prev {
          width: 38rem;
          height: 42rem;
          margin-top: -21rem;
          margin-left: -26rem;
        }
        &-next {
          width: 38rem;
          height: 42rem;
          margin-top: -21rem;
          margin-right: -26rem;
        }
      }
      &-pagination {
        &:not(:first-child) {
          margin-top: 16rem;
        }
      }
    }
  }
}
