.search-wrap {
  width: 100%;
  text-align: right;
  //padding: 16rem 0;
  .search-elem {
    display: flex;
    justify-content: flex-end;
    height: auto;
    .select {
      flex: 0 0 80rem;
      height: 40rem;
      border-radius: 4px;
      border: 1px solid $--clr-lightgray-2;
      padding: 9rem 20rem 9rem 10rem;
      font-size: 13rem;
      font-weight: 400;
      width: 100%;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-color: $--clr-white;
      background-image: var(--icon-sel-arr);
      background-repeat: no-repeat;
      background-position: right 10rem center;
      background-size: 14rem;
    }
    .search-input {
      flex: 1;
      height: 40rem;
      border-radius: 4px;
      border: 1px solid $--clr-lightgray-2;
      padding: 9rem 10rem;
      margin: 0 6rem;
      font-size: 13rem;
      max-width: 240rem;
    }
    .btn-search {
      height: 40rem;
      flex: 0 0 55rem;
      font-size: 13rem;
      font-weight: 700;
      color: $--clr-white;
      border-radius: 4px;
      background-color: $--clr-point-1;
      padding: 9rem 16rem;
    }
  }
}
.notice_tb {
  display: block;
  text-align: left;
  & > div {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $--clr-lightgray-2;
    .notice_index {
      width: 100rem;
      text-align: center;
      span {
        font-size: 13rem;
        font-weight: 400;
        &.icon {
          display: inline-block;
          width: 16rem;
          min-width: 16rem;
          height: 16rem;
          background-image: var(--icon-pin);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
    .notice_box {
      display: flex;
      flex: 1;
      text-align: left;
      padding: 17rem 0 16rem;
      .notice_tit {
        flex: 1;
        &:hover {
          cursor: pointer;
          span {
            color: $--clr-point-1;
          }
        }
        span {
          font-size: 13rem;
          font-weight: 400;
          line-height: 23rem;
          transition: color 0.15s ease-in-out;
          strong {
            font-weight: 700;
          }
        }
      }
      .notice_day {
        flex: 0 0 auto;
        span {
          font-size: 13rem;
          font-weight: 400;
          line-height: 23rem;
          color: #777777;
        }
      }
    }

  }
}
.pagination-wrap {
  .pagination-btn {
    &.toPrev {
      width: 32rem;
      height: 32rem;
      border-radius: 4px;
      border: 1px solid var(--dark-e-6, $--clr-lightgray-2);
      background: url(/image/icon/pagination_left.svg) no-repeat center center;
      background-size: auto;
    }
    &.toNext {
      width: 32rem;
      height: 32rem;
      border-radius: 4px;
      border: 1px solid var(--dark-e-6, $--clr-lightgray-2);
      background: url(/image/icon/pagination_right.svg) no-repeat center center;
      background-size: auto;
    }
    &.toFront {
      display: none;
    }
    &.toBack {
      display: none;
    }
  }
  .pagination-list {
    .pagination-elem {
      & > a {
        padding: 5rem 12rem;
        width: 32rem;
        height: 32rem;
        span {
          font-size: 13rem;
          font-style: normal;
          font-weight: 400;
        }
        &.active {
          border-radius: 4px;
          background: rgba(248, 183, 61, 0.12);
          span {
            color: $--clr-point-1;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1023rem) {
  .search-wrap {
    .search-elem {
      .select {
        flex: 0 0 80rem;
        height: 40rem;
        border-radius: 4px;
        border: 1px solid $--clr-lightgray-2;
        padding: 9rem 10rem;
        font-size: 13rem;
        font-weight: 400;
        width: 100%;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: $--clr-white;
        background-image: var(--icon-sel-arr);
        background-repeat: no-repeat;
        background-position: right 10rem center;
        background-size: 14rem;
        padding-right: 20rem;
      }
      .search-input {
        flex: 1;
        height: 40rem;
        border-radius: 4px;
        border: 1px solid $--clr-lightgray-2;
        padding: 9rem 10rem;
        margin: 0 6rem;
        font-size: 13rem;
        width: 100%;
      }
      .btn-search {
        height: 40rem;
        flex: 0 0 55rem;
        font-size: 13rem;
        font-weight: 700;
        color: $--clr-white;
        border-radius: 4px;
        background-color: $--clr-point-1;
        padding: 9rem 16rem;
      }
    }
  }
  .notice_tb {
    display: block;
    text-align: left;
    & > div {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $--clr-lightgray-2;
      .notice_index {
        width: 40rem;
        text-align: center;
      }
      .notice_box {
        display: block;
        flex: 1;
        text-align: left;
        padding: 12rem 8rem 11rem;
        .notice_tit {
          display: block;
          span {
            font-size: 13rem;
            font-weight: 400;
            line-height: 23rem;
            strong {
              font-weight: 700;
            }
          }
        }
        .notice_day {
          display: block;
          span {
            font-size: 13rem;
            font-weight: 400;
            line-height: 23rem;
            color: #777777;
          }
        }
      }

    }
  }
  .pagination-wrap {
    padding: 32rem 0 16rem;
    .pagination-btn {
      &.toPrev {
        width: 32rem;
        height: 32rem;
        border-radius: 4px;
        border: 1px solid var(--dark-e-6, $--clr-lightgray-2);
        background: url(/image/icon/pagination_left.svg) no-repeat center center;
        background-size: auto;
      }
      &.toNext {
        width: 32rem;
        height: 32rem;
        border-radius: 4px;
        border: 1px solid var(--dark-e-6, $--clr-lightgray-2);
        background: url(/image/icon/pagination_right.svg) no-repeat center center;
        background-size: auto;
      }
      &.toFront {
        display: none;
      }
      &.toBack {
        display: none;
      }
    }
    .pagination-list {
      .pagination-elem {
        & > a {
          padding: 5rem 12rem;
          width: 32rem;
          height: 32rem;
          span {
            font-size: 13rem;
            font-style: normal;
            font-weight: 400;
          }
          &.active {
            border-radius: 4px;
            background: rgba(248, 183, 61, 0.12);
            span {
              color: $--clr-point-1;
            }
          }
        }
      }
    }
  }
}
