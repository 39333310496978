// MO SET
@media screen and (max-width: 1023px) {
  .for_pc {
    display: none !important;
  }
  .for_mo {
    display: block !important;
  }
  img.for_mo {
    display: inline-block !important;
  }

  .highlighted {
    word-break: keep-all;
    &-wrap {
      & > * {
        word-break: keep-all;
      }
    }
    span, b, p {
      word-break: keep-all;
    }
    b {
      font-weight: 700;
    }
    &:before {
      bottom: 2px;
    }
  }

  .main {
    &-point {
      font-size: 28rem;
      font-weight: 400;
      line-height: normal;
      &.fs {
        &-80 {
          font-size: 34rem;
          font-weight: 400;
          line-height: 1.24;
        }
        &-56 {
          font-size: 28rem;
          font-weight: 400;
          line-height: 1.35;
        }
      }
    }
    &-subpoint {
      font-size: 14rem;
      font-weight: 400;
      line-height: normal;
      word-break: keep-all;
    }
    &-title {
      font-size: 28rem;
      font-weight: 700;
      line-height: 1.35;
      word-break: keep-all;
      &.fs {
        &-50 {
          font-size: 34rem;
          font-weight: 700;
          line-height: 1.12;
        }
      }
    }
    &-mdtitle {
      font-size: 20rem;
      font-weight: 700;
      line-height: 1.5;
      word-break: keep-all;
    }
    &-subtitle {
      font-size: 18rem;
      font-weight: 700;
      line-height: 1.44;
      word-break: keep-all;
      &.fs {
        &-30 {
          font-size: 18rem;
          font-weight: 700;
          line-height: 1.4;
        }
        &-28 {
          font-size: 14rem;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
    &-smtitle {
      font-family: 'Pretendard', sans-serif;
      font-size: 18rem;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: -0.2px;
      word-break: keep-all;
    }
    &-text {
      font-size: 16rem;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: -0.2px;
      word-break: break-word;
      &.fs {
        &-23 {
          font-size: 16rem;
          font-weight: 400;
          line-height: 1.5;
        }
      }
    }
  }

  .tag {
    padding: 8rem 13rem;
    font-size: 14rem;
    font-weight: 400;
    line-height: 1;
    &-wrap {
      .tag {
        min-width: 65rem;
        padding: 10rem 12rem;
        font-size: 14rem;
        font-weight: 600;
        &:not(:last-child) {
          margin-right: 8rem;
        }
      }
    }
  }

  .btn {
    &-md {
      max-height: 39rem;
      padding: 10rem 20rem;
      font-size: 14rem;
      line-height: 1.35;
      &.round {
        border-radius: 39px;
      }
    }
    &-sm {
      max-height: 30rem;
      padding: 8rem 16rem;
      font-size: 14rem;
      line-height: 1;
      &.round {
        border-radius: 30rem;
      }
    }
    &-arrow {
      padding: 0;
      &.ico-only {
        &.for_main {
          width: 20rem;
          height: 11rem;
          bottom: calc(4.878vh - 11rem);
          left: 50%;
        }
      }
    }
  }

  .tab {
    &-wrap {
      height: 60rem;
      max-height: 60rem;
      padding: 0 24rem;
      &:before {
        height: 59rem;
      }
      &.fixed {
        transform: translate(0, 60rem);
        &:before {
          transform: translate(0, -60rem);
        }
      }
    }
    &-contents {
      &__inner {
        padding: 100rem 16rem 48rem;
      }
    }
    &-elem {
      display: block;
      width: auto;
      height: auto;
      margin: 0 12rem;
      & > a {
        label {
          //font-size: 16rem;
          font-size: min(16rem, calc(100vw * 0.04));
          &:after {
            margin-top: 11rem;
          }
        }
      }
    }
    &-label {
      //font-size: 16rem;
      font-size: min(16rem, calc(100vw * 0.04));
      &:after {
        margin-top: 11rem;
      }
    }
    &-title {
      font-size: 28px;
      font-weight: 700;
      line-height: 34px;
      letter-spacing: -0.84px;
      word-break: keep-all;
      //margin: 0 0 80rem;
      & + .tab-subtitle {
        margin: 40rem 0 20rem;
      }
      & + .tab-text {
        margin: -80rem 0 80rem;
      }
      & + .board-wrap, & + .addservice-wrap {
        margin-top: 64rem;
      }
    }
    &-subtitle {
      font-size: 16rem;
      font-weight: 400;
      line-height: 1.25;
      letter-spacing: normal;
      color: $--clr-gray-2;
      word-break: keep-all;
      //margin: 0 0 20rem;
    }
    &-text {
      font-size: 13rem;
      font-weight: 400;
      line-height: 1.38;
      color: $--clr-gray-1;
      word-break: break-word;
      //margin: 0 0 40rem;
    }
  }

  .table {
    &-wrap {
      padding-bottom: 16px;
      &:not(:first-child) {
        margin-top: 70rem;
      }
    }
    &-elem {
      &.list {
        tr {
          td {
            padding: 20rem 10rem;
            &:not(:first-child):not(:last-child) {
              padding: 0;
            }
          }
        }
        a {
          &.tag {
            height: 20rem;
            padding: 5rem 10rem;
            border-radius: 20rem;
            font-size: 10rem;
          }
          &[onclick] {
            font-size: 14rem;
            line-height: 1;
          }
        }
        .date {
          font-size: 12rem;
          line-height: 1;
        }
      }
      tr {
        th {
          font-size: 14rem;
          white-space: nowrap;
        }
        td {
          font-size: 14rem;
          font-weight: 400;
          white-space: nowrap;
        }
      }
    }
    &-title {
      .unit {
        font-size: 14rem;
      }
    }
  }

  .header.fixed {
    & + .frame-container {
      //height: 363rem;
      margin-top: 60rem;
    }
  }
  .frame {
    &-container {
      position: relative;
      width: 100%;
      height: auto;
      &.fullpage {
        height: calc(100vh - 60rem);
      }
      &-bg {
        height: calc(100% + 60rem);
        top: -60rem;
      }
      &-text {
        width: 100%;
        height: 363rem;
        margin-top: -30rem;
        .text_wrap {
          width: 100%;
        }
      }
    }
    &-contents {
      min-height: calc(100% - 246rem);
      &.for_terms {
        min-height: calc(100vh - 306rem);
        margin-top: 60rem;
      }
      &__inner {
        padding: 0 16rem;
        &.with_img {
          &:nth-child(2n) {
            background-color: $--clr-white;
          }
          &:nth-child(2n-1) {
            & > * {
              &:first-child:last-child {
                flex-direction: column-reverse;
                .text_wrap {
                  margin-top: 36rem;
                }
              }
            }
          }
          & > * {
            &:first-child:last-child {
              flex-direction: column;
              align-items: unset;
              & > * {
                &:not(:first-child) {
                  margin-left: unset;
                  margin-top: 36rem;
                }
              }
            }
            .img_wrap {
              img {
                width: 100%;
                //height: 213rem;
                height: calc(100vw * 0.568);
              }
            }
            .text_wrap {
              text-align: left;
              .main {
                &-mdtitle {
                  text-align: left;
                  &:not(:last-child) {
                    margin-bottom: 12rem;
                  }
                }
              }
            }
          }
        }
      }
    }
    &-subpoint {
      font-size: 22rem;
      &:not(:last-child) {
        margin-bottom: 10rem;
      }
    }
    &-title {
      font-size: 34rem;
      line-height: 1.29;
    }
  }

  .detail {
    &-wrap {
      flex-direction: column;
      padding: 22rem 16rem;
      &:not(:first-child) {
        margin-top: 40rem;
      }
    }
    &-title {
      text-align: left;
    }
    &-text {
      margin-left: unset;
      text-align: left;
    }
  }

  .board {
    &-wrap {
      margin-top: 40rem !important;
      .img_wrap {
        max-height: 136px;
      }
      &.media, &.bigdata {
        padding: 0;
        .board-title {
          display: none;
        }
        .media_grid, .bigdata_grid {
          ul {
            & > li {
              flex: 1 1 100%;
              margin-top: 20rem;
              &:not(:nth-child(1)), &:not(:nth-child(2)) {
                margin-top: 20rem;
              }
              & > .flex.vert {
                .video, .image {
                  border-radius: 10rem;
                  iframe, a {
                    height: 184rem;
                  }
                }
                .info {
                  .title {
                    font-size: 16rem;
                    font-weight: 700;
                    line-height: 1.25;
                    letter-spacing: -0.48px;
                  }
                  .title_tag {
                    font-size: 13rem;
                    font-weight: 400;
                    line-height: 1.77;
                    letter-spacing: normal;
                    text-align: left;
                    color: $--clr-gray-5;
                  }
                }
              }
              &:nth-child(2n-1) {
                width: 100%;
                max-width: 100%;
                margin-right: 0;
                & > .flex.vert {
                  align-items: center;
                }
              }
              &:nth-child(2n) {
                width: 100%;
                max-width: 100%;
                margin-left: 0;
                & > .flex.vert {
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
    &-img {
      max-width: 100px;
      height: 80rem;
      max-height: 80px;
      border-radius: 8rem;
    }
    &_img {
      max-height: unset;
      &-wrap {
        max-height: unset;
        padding: 0;
        &:not(:last-child) {
          margin-bottom: 50rem;
        }
      }
    }
    &-title {
      flex-direction: column;
      justify-content: unset;
      align-items: flex-start;
      font-size: 20rem;
      font-weight: 700;
      line-height: 1;
      word-break: keep-all;
      .unit {
        font-size: 18rem;
        color: $--clr-gray-3;
      }
      &[style*='display: none'] ~ *[style*='display: block'], &[style*='display: none'] ~ *[style*='display: table'] {
        margin-top: 0;
      }
    }
    &-elem {
      &:not(:first-child) {
        margin-top: 46rem;
      }
      &.with_img {
        & + .pagination-wrap {
          margin-top: 40rem;
        }
        tr {
          td {
            padding: 20rem 10rem;
            span, p {
              &.date, & > .date {
                margin-top: 10rem;
                font-size: 12rem;
              }
            }
          }
        }
      }
      &.no_result {
        margin-top: 0;
        thead {
          tr {
            &:first-child:last-child {
              th, td {
                border-top: 0;
                .flex.vert {
                  min-height: 210rem;
                  padding-top: 72rem;
                  padding-bottom: 40rem;
                  & > span {
                    font-size: 16rem;
                    font-weight: 400;
                    word-break: keep-all;
                    white-space: normal;
                  }
                  .board-txt {
                    font-size: 16rem;
                    font-weight: 400;
                    word-break: keep-all;
                    white-space: normal;
                    &:not(:first-child) {
                      margin-top: 8rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .for_pc {
        display: none !important;
      }
      .for_mo {
        display: table-column !important;
      }
      tbody {
        tr {
          &:hover {
            td {
              &:not(.date):not(a):not(button):not(.center) {
                color: inherit;
              }
            }
          }
          td {
            .tag {
              font-size: 10rem;
              border-radius: 30rem;
              padding: 5rem 12rem;
              &:not(:last-child) {
                margin-bottom: 10rem;
              }
            }
            .flex {
              flex-direction: column;
              & > span {
                min-width: unset !important;
                margin-left: unset !important;
                text-align: left;
              }
            }
          }
        }
      }
      tr {
        th {
          padding: 20px 6px;
          font-size: 14rem;
          font-weight: 700;
          //line-height: 2.12;
          line-height: normal;
        }
        td {
          padding: 20px 6px;
          font-size: 14rem;
          font-weight: 400;
          //line-height: 2.12;
          line-height: normal;
          &.date {
            font-size: 12rem;
            font-weight: 400;
          }
        }
      }
    }
    &-detail {
      font-size: 16rem;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: -0.2rem;
      img {
        width: 100% !important;
        max-width: 100%;
        height: auto;
        max-height: unset;
        object-fit: contain;
      }
      iframe, video {
        width: 100%;
        height: unset;
        z-index: 10;
      }
      figure {
        &.image {
          width: auto;
          max-width: 100%;
          height: auto;
          max-height: unset;
          text-align: center;
          figcaption {
            font-size: 12rem;
          }
        }
        &.media, &.bigdata, iframe, video, image {
          width: 100%;
          height: unset;
          z-index: 10;
        }
        &.table {
        }
      }
    }
    &-attachment {
      padding: 32rem 16rem;
      &:not(:first-child) {
        margin-top: 32rem;
      }
      .attachment-elem {
        display: flex !important;
        & + .attachment-elem {
          margin-top: 16rem;
        }
        & > span {
          font-size: 16rem;
          font-weight: 400;
          line-height: 1.25;
          text-decoration: unset;
        }
        &:before {
          width: 24rem;
          height: 24rem;
        }
      }
    }
    &-navigator {
      padding-top: 40rem;
      .btn {
        font-size: 16rem;
        font-weight: 600;
        &.navigator {
          &-prev {
            &:before {
              width: 20rem;
              height: 20rem;
              margin-right: 5rem;
            }
          }
          &-next {
            &:after {
              width: 20rem;
              height: 20rem;
              margin-left: 5rem;
            }
          }
        }
      }
    }
    &-other {
      margin: 55rem 0 40rem;
      &-title {
        padding: 0;
        font-size: 20rem;
        font-weight: 700;
        line-height: 1.7;
        &:not(:last-child) {
          margin-bottom: 32rem;
        }
        &.list {
          padding: 0;
          margin-bottom: 25rem;
        }
      }
      &-text {
        font-size: 16rem;
        font-weight: 600;
        line-height: 1.43;
      }
      &-date {
        font-size: 14rem;
        font-weight: 400;
        line-height: 1;
      }
      &-img {
        height: 200rem;
        border-radius: 12rem;
      }
      .img_wrap {
        &:not(:last-child) {
          margin-bottom: 20rem;
        }
      }
      .swiper {
        width: calc(100% + 24rem);
        padding: 0 24rem 0 0;
        &:before {
          display: none;
        }
        &:after {
          display: none;
        }
        &-button {
          &-prev {
            display: none;
          }
          &-next {
            display: none;
          }
        }
      }
    }
  }

  .pagination {
    &-wrap {
      //margin-top: 46rem;
      .for_pc {
        display: none !important;
      }
    }
  }

  .select-wrap {
    .select {
      &-input {
        font-size: 14rem;
      }
    }
    .btn {
      &-input {
        &:checked {
          & ~ .option-list {
            max-height: 800px;
          }
        }
      }
      &-select {
        font-size: 14rem;
        min-height: 48rem;
        &::after {
          width: 13px;
          min-width: 13px;
          height: 9px;
          margin-left: 4px;
        }
      }
    }
    .option-list {
      max-width: calc(100% - 48rem);
      //margin-top: 47rem;
      transform: translate(0, 47rem);
      .btn {
        &-option {
          min-height: 48rem;
          max-height: 48rem;
          font-size: 14rem;
        }
      }
    }
  }

  .search {
    &-wrap {
      display: block;
      position: relative;
      width: 100%;
      height: auto;
    }
    &-elem {
      max-width: unset;
    }
    &-input {
      max-width: unset;
    }
  }

  .title {
    &-detail {
      &:not(:last-child) {
        margin-bottom: 15rem;
      }
      & > span {
        font-size: 12rem;
        font-weight: 400;
        &:not(:first-child) {
          &:before {
            height: 50%;
            min-height: 11rem;
            margin: auto 8rem;
          }
        }
      }
    }
  }

  .addservice {
    &-wrap {
      position: relative;
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;
      height: auto;
      & > * {
        flex: 1 1 50%;
        width: 100%;
        min-height: 184rem;
        margin: 0;
        &.block_box {
          display: inline-flex;
          flex-direction: column;
          padding: 32rem;
        }
        .img_wrap {
          position: relative;
          display: inline-block;
          width: auto;
          height: auto;
          margin: 0;
          margin: 0;
          img {
            position: relative;
            display: inline-block;
            object-fit: contain;
          }
        }
      }
      a:not(.tag), button {
        display: inline-block;
        width: auto;
        height: auto;
        font-size: 16rem;
        font-weight: 400;
        line-height: 1.25;
        letter-spacing: normal;
        color: $--clr-black;
        &:hover {
          text-decoration: underline;
        }
        &.btn {
          display: inline-block;
          width: auto;
          height: auto;
          padding: 10rem 16rem;
          background-color: $--clr-point-1;
          border-radius: 8rem;
          font-size: 13rem;
          font-weight: 700;
          line-height: 1.77;
          letter-spacing: normal;
          color: $--clr-white;
          &:hover {
            text-decoration: unset;
          }
        }
      }
      .btn_wrap {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        padding: 0;
        &:not(:first-child) {
          margin-top: 32rem;
        }
        & > * {
          &:not(:last-child) {
            margin-right: 5rem;
          }
          &:not(:first-child) {
            margin-left: 5rem;
          }
        }
      }
      .mxw {
        &-382, &-604 {
          max-width: 100%;
          padding: 32rem 0;
        }
      }
      .img {
        &.size {
          &-64 {
            width: 64rem;
            min-width: 64rem;
            height: 64rem;
            margin: 0 auto;
          }
          &-80 {
            width: 80rem;
            min-width: 80rem;
            height: 80rem;
            margin: 0 auto;
          }
        }
      }
      &.for_delivery {
        flex-direction: column;
        flex-wrap: nowrap;
        & > * {
          flex-direction: column;
          padding: 32rem;
          & > .flex {
            flex-direction: column !important;
            align-items: center !important;
            * {
              text-align: center;
            }
            .img_wrap {
              width: 100%;
            }
            &.img_change {
              position: relative;
              & > div {
                &:first-child {
                  margin-top: 150rem;
                }
                & > .img_wrap {
                  position: absolute;
                  display: inline-block;
                  width: 100%;
                  height: 134rem;
                  top: 0;
                  left: 0;
                }
              }
            }
          }
          &:not(:nth-child(1)) {
            margin-top: 20rem;
          }
          &:nth-child(2n):not(:nth-child(4)) {
            max-width: 100%;
            margin-right: 0;
          }
          &:nth-child(2n+1):not(:nth-child(1)) {
            max-width: 100%;
            margin-left: 0;
          }
          &:nth-child(1) {
            flex: unset;
            max-width: 100%;
            min-height: 280rem;
            padding: 32rem 24rem;
            .addservice-text {
              &:not(:last-child) {
                margin-bottom: 32rem;
              }
            }
          }
          &:nth-child(4) {
            flex: unset;
            justify-content: center;
            max-width: 100%;
            min-height: 219rem;
            padding: 32rem;
            & > .flex {
              align-items: center;
              .addservice-title {
                margin-bottom: 0;
              }
              .btn {
                margin-top: 16rem;
                margin-left: 0;
              }
            }
          }
          .img_wrap {
            margin-right: 0;
            img {
              position: relative;
              display: inline-block;
              object-fit: contain;
              &.img-01 {
                width: 278rem;
                height: 174rem;
              }
              &.img-02 {
                width: 208rem;
                height: 134rem;
              }
            }
            & + .btn {
              margin-top: 16rem;
              float: unset;
            }
          }
          .addservice-title {
            & + .small {
              margin-top: 10rem;
            }
          }
        }
      }
      &.for_taxi {
        & > * {
          &:nth-child(1) {
            padding: 0;
            & > .flex {
              align-items: center;
              flex-direction: column;
              padding: 40rem 16rem 0;
              &:before {
                order: 2;
                content: '';
                display: inline-block;
                width: 1px;
                min-width: 1px;
                height: 100%;
                max-height: 498rem;
                background-color: $--clr-lightgray-2;
              }
              .img_wrap {
                order: 1;
                width: 100%;
                text-align: center;
              }
              .flex.vert {
                width: 100%;
                min-width: unset;
                order: 3;
                justify-content: center;
                .flex {
                  height: auto;
                  & + .flex {
                    margin-top: 40rem;
                  }
                }
              }
            }
          }
          .img_wrap {
            img {
              &.img-01 {
                width: 203rem;
                height: 389rem;
              }
            }
            & + .btn {
              margin-top: 16rem;
              float: right;
            }
          }
          &.mxw-604 {
            padding: 32rem 0 0;
          }
        }
      }
      &.for_shopping {
        & > * {
          text-align: center;
          &:nth-child(1) {
            padding: 48rem 0 0;
            & > .flex.vert {
              justify-content: center;
              align-items: center;
              & > .addservice-title {
                margin: 16rem 0 0;
                & + .addservice-title {
                  margin-top: 48rem;
                }
              }
              & > .flex {
                padding: 0;
                align-items: center;
                &:not(:first-child) {
                  margin-top: 36rem;
                }
                .img_wrap {
                  order: 1;
                  text-align: center;
                  & + .img_wrap {
                    order: 3;
                    & + .img_wrap {
                      order: 5;
                    }
                  }
                }
                &:before, &:after {
                  height: 80px;
                  max-height: 80rem;
                }
                &:before {
                  order: 2;
                }
                &:after {
                  order: 4;
                }
              }
              & > .block_box {
                display: inline-block;
                width: 100%;
                height: auto;
                padding: 40rem 20rem;
                &:not(:first-child) {
                  margin-top: 48rem;
                }
                .flex {
                  height: auto;
                  flex-direction: column;
                  &:not(:first-child) {
                    margin-top: 20rem
                  }
                }
                .block_box {
                  .img_wrap {
                    width: 100%;
                    text-align: center;
                    &:not(:first-child) {
                      margin-top: 24rem;
                    }
                    img {
                      width: 240rem;
                      height: 135rem;
                    }
                  }
                  &.orange {
                    margin-bottom: 10rem;
                    margin-right: 0;
                  }
                  &.blue {
                    margin-top: 10rem;
                    margin-left: 0;
                  }
                }
              }
            }
          }
          .img_wrap {
            &.vert {
              display: inline-flex;
              flex-direction: column;
              align-items: center;
              .addservice-text {
                &:not(:first-child) {
                  margin-top: 20rem;
                }
              }
              img {
                &.size-80 {
                  width: 60rem;
                  min-width: 60rem;
                  height: 60rem;
                }
              }
            }
          }
        }
      }
      &.for_emall, &.for_direct, &.for_share, &.for_subsidy {
        flex-direction: column;
        & > * {
          text-align: center;
          &:nth-child(1) {
            padding: 20rem 0 0;
            .img_wrap {
              display: inline-block;
              width: 100%;
              height: auto;
              text-align: center;
              img {
                width: 328rem;
                height: 205rem;
                object-fit: contain;
              }
            }
          }
          &:nth-child(2) {
            padding: 48rem 20rem;
            & > .flex {
              flex-direction: column;
              align-items: center;
              padding: 0;
              &:not(:first-child) {
                margin-top: 36rem;
              }
              .img_wrap {
                order: 1;
                width: 100%;
                text-align: center;
                .addservice-text:not(.small) {
                  font-size: 20rem;
                  line-height: 1.4;
                  letter-spacing: -0.6px;
                }
                & + .img_wrap {
                  order: 3;
                  margin-top: 48rem;
                  & + .img_wrap {
                    order: 5;
                    margin-top: 48rem;
                  }
                }
              }
              &:before, &:after {
                display: none;
              }
              &:before {
                order: 2;
              }
              &:after {
                order: 4;
              }
            }
          }
          &:first-child:last-child {
            padding: 20rem 0 0;
            &.block_box {
              .img_wrap {
                & + .addservice-title {
                  margin-top: 64rem;
                }
              }
            }
          }
          .img_wrap {
            position: relative;
            display: inline-block;
            width: auto;
            height: auto;
            margin: 0;
            img {
              position: relative;
              display: inline-block;
              object-fit: contain;
              & + .addservice-text {
                margin-top: 20rem;
              }
            }
            &.vert {
              display: inline-flex;
              flex-direction: column;
              align-self: center;
            }
          }
          .addservice-text {
            &:not(:first-child) {
              margin-top: 8rem;
            }
            &.small {
              line-height: 1.38;
            }
          }
        }
      }
      &.for_voting, &.benefitUser, &.benefitBusiness {
        & > * {
          &:not(:nth-child(1)) {
           margin-top: 20rem;
          }
          &:nth-child(2n):not(:nth-child(6)) {
           max-width: calc(50% - 10rem);
           margin-right: 10rem;
          }
          &:nth-child(2n+1):not(:nth-child(1)) {
           max-width: calc(50% - 10rem);
           margin-left: 10rem;
          }
          &:nth-child(1) {
           flex: 2 1 100%;
           max-width: 100%;
           min-height: 204rem;
           padding: 48rem 0;
           .addservice-title {
             text-align: center;
           }
          }
          &:nth-child(6) {
           flex: 2 1 100%;
           max-width: 100%;
           min-height: 131rem;
           padding: 40rem 32rem;
           & > .flex {
             align-items: center;
             .addservice-title {
               margin-bottom: 0;
             }
             .btn {
               margin-left: auto;
             }
           }
          }
          .img_wrap {
            position: relative;
            display: inline-block;
            width: auto;
            height: auto;
            margin-top: 0;
            margin-left: 0;
            &.vert {
             display: inline-flex;
             flex-direction: column;
             align-items: flex-start;
             & > * {
               text-align: left;
             }
            }
            img {
             position: relative;
             display: inline-block;
             object-fit: contain;
             margin-left: 0;
             &.img-01 {
               width: 528rem;
               height: 330rem;
             }
             &.img-02 {
               width: 208rem;
               height: 134rem;
             }
            }
            .text_wrap {
              .addservice-text {
                display: block;
              }
            }
            & + .btn {
             margin-top: 16rem;
             float: right;
            }
          }
          .addservice-title {
            & + .small {
             margin-top: 10rem;
            }
          }
        }
      }
      &.benefitUser {
        margin-top: 40rem;
        & > * {
          min-height: 160rem !important;
          margin: 0;
          &:nth-child(3n-2) {
            flex: unset;
            width: 100%;
            height: auto;
            padding: 32rem 24rem;
            max-width: 100% !important;
            margin-top: 0;
            margin-left: 0;
            margin-right: 0;
          }
          &:nth-child(3n-1) {
            flex: unset;
            width: 100%;
            height: auto;
            padding: 32rem 24rem;
            max-width: 100% !important;
            margin-top: 0;
            margin-left: 0;
            margin-right: 0;
          }
          &:nth-child(3n) {
            flex: unset;
            width: 100%;
            height: auto;
            padding: 32rem 24rem;
            max-width: 100% !important;
            margin-top: 0;
            margin-left: 0;
            margin-right: 0;
          }
          &:not(:nth-child(1)) {
            margin-top: 20rem !important;
          }
          .img_wrap.vert {
            flex-direction: row !important;
            align-items: flex-start !important;
            .img {
              margin-right: 0;
              & + .addservice-text, & + .text_wrap {
                margin-top: 0 !important;
                margin-left: 24rem !important;
              }
            }
          }
          .text_wrap {
            margin-top: 0;
          }
        }
      }
      &.benefitBusiness {
        margin-top: 40rem;
        & > * {
          flex-direction: row;
          align-items: center;
          min-height: 160rem !important;
          margin: 0;
          & > .img_wrap {
            .img {
              width: 95rem;
              height: 85rem;
            }
          }
          & > .text_wrap {
            margin-left: 32rem;
            .addservice-title {
              width: auto;
              text-align: left;
            }
            .addservice-text {
              width: 100%;
            }
          }
          &:nth-child(2n-1) {
            flex: unset;
            width: 100%;
            height: auto;
            padding: 32rem 24rem;
            max-width: 100% !important;
            margin-top: 0;
            margin-left: 0 !important;
            margin-right: 0;
          }
          &:nth-child(2n) {
            flex: unset;
            width: 100%;
            height: auto;
            padding: 32rem 24rem;
            max-width: 100% !important;
            margin-top: 0;
            margin-left: 0;
            margin-right: 0 !important;
          }
          &:not(:nth-child(1)) {
            margin-top: 20rem !important;
          }
        }
      }
      &.for_voting {
        flex-direction: column;
        & > * {
          flex: unset;
          &:not(:nth-child(1)) {
            margin-top: 20rem;
          }
          &:nth-child(2n):not(:nth-child(6)) {
            max-width: 100%;
            margin-right: 0;
          }
          &:nth-child(2n+1):not(:nth-child(1)) {
            max-width: 100%;
            margin-left: 0;
          }
          &:nth-child(1) {
            flex: unset;
            max-width: 100%;
            min-height: unset;
            padding: 20rem 0;
            .addservice-title {
              text-align: center;
            }
          }
          &:nth-child(6) {
            flex: unset;
            max-width: 100%;
            min-height: 237rem;
            padding: 40rem 32rem;
            & > .flex {
              flex-direction: column;
              align-items: center;
              .addservice-title {
                margin-bottom: 0;
                text-align: center;
              }
              .addservice-text {
                text-align: center;
              }
              .btn {
                margin-top: 16rem;
                margin-left: 0;
              }
            }
          }
          .img_wrap {
            position: relative;
            display: inline-block;
            width: auto;
            height: auto;
            margin-top: 0;
            margin-left: 0;
            &.vert {
              display: inline-flex;
              flex-direction: column;
              align-items: flex-start;
              & > * {
                text-align: left;
              }
            }
            img {
              position: relative;
              display: inline-block;
              object-fit: contain;
              margin-left: 0;
              &.img-01 {
                width: 528rem;
                height: 330rem;
              }
              &.img-02 {
                width: 208rem;
                height: 134rem;
              }
            }
            .addservice-text {
              &:not(.small) {
                font-size: 16rem;
                line-height: 1.25;
                letter-spacing: -0.48px;
              }
              &:not(:first-child) {
                margin-top: 16rem;
              }
            }
            & + .btn {
              margin-top: 16rem;
              float: right;
            }
          }
          .addservice-title {
            & + .small {
              margin-top: 10rem;
            }
          }
        }
      }
      &.for_about {
        flex-wrap: nowrap;
        flex-direction: column;
        & > * {
          flex: 1 1 100%;
          text-align: center;
          padding: 40rem 24rem;
          &:nth-child(1) {
            & > .flex {
              flex-wrap: unset;
              flex-direction: column;
              align-items: center;
              padding: 0;
              &:not(:first-child) {
                margin-top: 36rem;
              }
              .img_wrap {
                order: 1;
                flex: 1 1 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                height: 274rem;
                margin: 0;
                padding: 0 0 40rem;
                text-align: center;
                & > * {
                  &:nth-child(1) {
                    margin-bottom: auto;
                  }
                }
                & + .img_wrap {
                  order: 3;
                  padding: 40rem 0;
                  & + .img_wrap {
                    order: 5;
                    padding: 40rem 0;
                  }
                }
              }
              &:before, &:after {
                content: '';
                display: inline-block;
                width: 120rem;
                height: 1px;
                margin: 0 20rem;
              }
              &:before {
                order: 2;
              }
              &:after {
                order: 4;
              }
            }
            & > .block_box {
              display: flex;
              align-items: center;
              height: 224rem;
              &:not(:first-child) {
                margin-top: 40rem;
                .flex {
                  flex-direction: column-reverse;
                  .addservice-title {
                    margin: auto 0;
                    text-align: center;
                    color: #F8B73D;
                  }
                  .img_wrap {
                    width: 100%;
                  }
                }
              }
            }
          }
          &.tab-subtitle {
            display: inline-block;
            width: 100%;
            height: auto;
            min-height: unset;
            margin: 80rem 0 0;
            padding: 0;
            text-align: left;
          }
          &.block_box {
            display: inline-block;
            width: 100%;
            height: auto;
            padding: 40rem 24rem;
            &:not(:first-child) {
              margin-top: 40rem;
            }
            .flex {
              &:not(:first-child) {
                margin-top: 48rem;
                .addservice-title {
                  &.big {
                    margin-bottom: 0;
                    & + .addservice-text {
                      margin-top: 8rem;
                    }
                  }
                }
                .addservice-text {
                  & + .addservice-text {
                    margin-top: 8rem;
                  }
                }
              }
            }
            .block_box {
              .img_wrap {
                text-align: center;
                &:not(:first-child) {
                  margin-top: 24rem;
                }
                img {
                  width: 320rem;
                  height: 180rem;
                }
              }
              &.orange {
                margin-right: 10rem;
              }
              &.blue {
                margin-left: 10rem;
              }
            }
          }
          .img_wrap {
            img {
              &.img {
                &-01 {
                  position: absolute;
                  width: 80rem !important;
                  height: 120rem !important;
                  right: unset;
                  bottom: unset;
                  top: -80rem;
                  transform: translate(-50%, -50%);
                }
                &-02 {
                  height: 220rem;
                }
                &-03 {
                  height: 297rem;
                }
              }
            }
            &.vert {
              display: inline-flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              .addservice-title {
                &.max {
                  margin-bottom: 8rem;
                }
              }
              .addservice-text {
                &:not(:first-child) {
                  margin-bottom: 8rem;
                }
              }
            }
          }
        }
        &.usage {
          padding: 0;
          & > .flex {
            flex-direction: column;
            flex-wrap: unset;
            padding: 0;
            & > .block_box {
              width: auto;
              height: auto;
              padding: 48rem 24rem;
              & + .block_box {
                margin-top: 20rem;
              }
              &:nth-child(2n-1) {
                max-width: 100%;
                margin-right: 0;
              }
              &:nth-child(2n) {
                max-width: 100%;
                margin-left: 0;
              }
              .img_wrap {
                &.vert {
                  .addservice-text:not(.small) {
                    font-size: 16rem;
                    line-height: 1.25;
                  }
                  img {
                    width: 280rem;
                    height: 218rem;
                  }
                }
              }
            }
          }
        }
      }
    }
    &-title {
      font-size: 20rem;
      font-weight: 700;
      line-height: 1.4;
      letter-spacing: -0.6px;
      color: $--clr-black;
      word-break: keep-all;
      &.big {
        font-size: 16rem;
        font-weight: 400;
        line-height: 1.25;
        letter-spacing: -0.48px;
        word-break: keep-all;
      }
      &.large {
        font-size: 24rem;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: -0.72px;
        word-break: keep-all;
      }
      &.max {
        font-size: 24rem;
        font-weight: 700;
        line-height: 1.25;
        letter-spacing: -0.72px;
        word-break: keep-all;
      }
      &:not(:last-child) {
        margin-bottom: 16rem;
      }
    }
    &-ico {
      width: 56rem;
      min-width: 56rem;
      height: 56rem;
      margin-right: 24rem;
    }
    &-text {
      font-size: 13rem;
      font-weight: 400;
      line-height: 1.38;
      letter-spacing: normal;
      &.small {
        font-size: 12rem;
        line-height: 1.5;
      }
    }
  }

  .registry {
    &-wrap {
      &:not(:first-child) {
        margin-top: 40rem;
      }
      & > * {
        flex: 1 1 100%;
        width: 100%;
        min-height: 192rem;
        &:nth-child(2n) {
          max-width: 100%;
          margin-right: 0;
        }
        &:nth-child(2n+1):not(:nth-child(1)) {
          max-width: 100%;
          margin-left: 0;
        }
        &:nth-child(1) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex: 1 1 100%;
          max-width: 100%;
          height: auto;
          min-height: 440rem;
          padding: 32rem 24rem !important;
          & > .flex {
            flex-direction: column;
          }
          .registry-text {
            width: 100%;
          }
          .img_wrap {
            width: 100%;
            margin-top: 0 !important;
            margin-left: 0;
            margin-right: 0;
            img {
              position: relative;
              width: auto;
              height: auto;
            }
            &:first-child {
              text-align: center;
              margin-right: 0;
              margin-bottom: 34rem;
              img {
                width: 100%;
                max-width: 280rem;
                height: auto;
              }
            }
            &:last-child {
              text-align: right;
              img {
                position: absolute;
                width: 200rem;
                height: 164rem;
                right: 0;
                transform: translate(0, 21rem);
              }
            }
          }
        }
        &.block_box {
          padding: 32rem 24rem;
          & > div {
            span {
              display: inline-block;
              width: auto;
              height: auto;
              font-size: 16rem;
              font-weight: 400;
              line-height: 1.25;
              letter-spacing: normal;
              color: $--clr-gray-2;
            }
            a, button {
              display: inline-block;
              width: auto;
              height: auto;
              font-size: 16rem;
              font-weight: 400;
              line-height: 1.25;
              letter-spacing: normal;
              color: $--clr-black;
              &:hover {
                text-decoration: underline;
              }
              &.big {
                margin-top: 0;
                font-size: 36rem;
                font-weight: 500;
                line-height: 1.17;
                letter-spacing: -1.08px;
                &:not(:first-child) {
                  margin-left: 24rem;
                }
              }
              &.btn {
                display: inline-block;
                width: auto;
                height: auto;
                margin: 0;
                padding: 10rem 16rem;
                background-color: $--clr-point-1;
                border-radius: 8rem;
                font-size: 13rem;
                font-weight: 700;
                line-height: 1.77;
                letter-spacing: normal;
                color: $--clr-white;
                &:hover {
                  text-decoration: unset;
                }
              }
            }
          }
        }
      }
    }
    &-title {
      font-size: 16rem;
      font-weight: 700;
      line-height: 1.25;
      letter-spacing: -0.48px;
      word-break: keep-all;
      &.big {
        span {
          font-size: 24rem !important;
          font-weight: 700 !important;
          line-height: 1.5 !important;
          letter-spacing: -0.72px !important;
          color: $--clr-gray-2 !important;
        }
      }
    }
    &-text {
      font-size: 15rem;
      font-weight: 400;
      line-height: 1.6;
      word-break: break-word;
    }
  }

  .inquiry {
    &-wrap {
      margin-top: 40rem;
      & > * {
        flex: 1 1 100%;
        order: 2;
        width: 100%;
        height: 184rem;
        &:not(:nth-child(1)):not(:nth-child(2)) {
          margin-top: 20rem;
        }
        &:nth-child(3n) {
          max-width: 100%;
          margin-right: 0;
        }
        &:nth-child(3n+1):not(:nth-child(1)) {
          max-width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
        &:nth-child(3n+2):not(:nth-child(2)) {
          max-width: 100%;
          margin-left: 0;
        }
        &:nth-child(1) {
          flex: 1 1 100%;
          max-width: 100%;
          margin-right: 0;
        }
        &:nth-child(2) {
          flex: 1 1 100%;
          max-width: 100%;
          margin-left: 0;
        }
        &:nth-child(4) {
          label.inquiry-text {
            display: block;
          }
        }
        &.block_box {
          padding: 32rem 24rem;
        }
        &.img_wrap {
          order: 1;
          height: 204rem;
          img {
            transform: translate(0, 0);
          }
        }
      }
    }
  }

  .bi {
    &-wrap {
      padding: 0;
      &:not(:first-child) {
        margin-top: 40rem;
      }
      & > * {
        &:not(:first-child) {
          margin-top: 64rem;
        }
        &.img_wrap {
          img {
            width: 160rem;
            height: 130rem;
          }
        }
        &.wrap {
          & > .flex {
            flex-direction: column;
            .img_wrap {
              & + .img_wrap {
                margin-top: 16rem;
              }
            }
          }
        }
      }
      .btn {
        width: 100%;
        &_wrap {
          flex-direction: column;
          align-items: center;
          & > * {
            max-width: 100%;
            &:not(:last-child) {
              margin-right: 0;
              margin-bottom: 5rem;
            }
            &:not(:first-child) {
              margin-left: 0;
              margin-top: 5rem;
            }
          }
        }
      }
    }
    &-img {
      &.ci {
        &-1 {
          width: 168rem;
          height: 78rem;
        }
        &-2 {
          width: 198rem;
          height: 72rem;
          transform: translate(-50%, calc(-50% - 18rem));
        }
        &-3 {
          width: 260rem;
          height: 48rem;
          transform: translate(-50%, calc(-50% - 24rem));
        }
      }
      &.bg-1 {
        object-position: left;
      }
      &.bg-2 {
        object-position: left;
      }
    }
  }

  .popup {
    &-wrap {
      width: calc(100% - 40rem);
      max-width: 320rem;
      padding: 0 0 42rem;
    }
    &-body {
      padding: 0;
      max-height: min(436rem, calc(100vh - 40rem));
    }
    &-contents {
      max-height: min(380rem, calc(100vh - 96rem));
      & > p {
        font-size: 14rem;
        font-weight: 300;
        line-height: 1.57;
        padding: 20rem;
        &:first-child {
          font-size: 20rem;
          font-weight: 800;
          line-height: 1.4;
        }
        &:last-child {
          padding: 0;
        }
      }
      & > hr {
        width: calc(100% + 40rem);
        margin: 20rem 0 20rem -20rem;
      }
      & > figure {
        margin: 0;
      }
    }
    &-btm {
      height: 42rem;
      padding: 0 20rem;
      .check {
        &-box {
          label {
            height: 22rem;
            font-size: 14rem;
            font-weight: 300;
            line-height: 1.57;
            letter-spacing: -0.035px;
            &:before {
              width: 14rem;
              min-width: 14rem;
              height: 14rem;
              margin-right: 6rem;
            }
          }
        }
      }
      .btn {
        font-size: 16rem;
        font-weight: 500;
        line-height: 1.88;
      }
    }
  }

  .agree {
    &-container {
      max-width: 1024rem;
      margin: 30rem auto;
      padding: 0 16rem;
      & > .flex {
        margin: 20rem 0;
        .select-wrap {
          justify-content: flex-end;
          width: 100%;
          .btn-select {
            width: 100%;
            max-width: 100%;
          }
          .option-list {
            width: 100%;
            max-width: calc(100% - 32rem);
            transform: translate(0, calc(100% - 1px));
          }
        }
      }
    }
    &-wrap {
      max-width: 100%;
      h5 {
        font-size: 20rem;
        font-weight: 700;
        line-height: 1.33;
        letter-spacing: -0.2px;
      }
      p {
        font-size: 16rem;
        font-weight: 400;
        line-height: 1.75;
        letter-spacing: -0.2px;
      }
      .table {
        width: 100%;
        overflow-x: auto;
      }
      table {
        tr {
          th {
            padding: 20rem;
            font-size: 14rem;
            font-weight: 700;
            line-height: 1.57;
            white-space: nowrap;
          }
          td {
            padding: 15rem;
            font-size: 14rem;
            font-weight: 400;
            line-height: 1.57;
            letter-spacing: -0.035px;
            white-space: nowrap;
          }
        }
      }
    }
    &-title {
      font-size: 28rem;
      font-weight: 800;
      line-height: 1.21;
      word-break: keep-all;
    }
  }
}