input {
  color: $--clr-black;
  transition: border 0.15s ease-in-out;
  *, &:focus, &:focus * {
    &:not(::placeholder):not([type='radio']):not([type='checkbox']) {
      color: $--clr-black;
    }
  }
  &:active, &:focus {
    border: 1px solid $--clr-point-1 !important;
  }
}
select {
  color: $--clr-black;
  transition: border 0.15s ease-in-out;
  &:active, &:focus {
    border: 1px solid $--clr-point-1 !important;
  }
}
button, a {
  color: $--clr-black;
}


.for_pc {
  display: block;
}
img.for_pc {
  display: inline-block;
}
.for_mo {
  display: none !important;
}

  // BG COLOR
  html {
    background-color: $--clr-point-bg;
  }
  body {
    background-color: $--clr-point-bg;
    & > .wrap {
      background-color: $--clr-point-bg;
      & > .header {
        background-color: $--clr-point-bg;
      }
      .frame {
        &-contents {
          &__inner {
            background-color: $--clr-point-bg;
          }
          .tab {
            &-contents {
              background-color: $--clr-point-bg;
            }
          }
        }
      }
    }
  }

  .sr-only {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
    white-space: nowrap;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }

  b, strong {
    font-weight: 700 !important;
  }

  // FONT COLOR
  .f-clr {
    &-point {
      color: $--clr-point-1 !important;
    }
    &-point2 {
      color: $--clr-point-2 !important;
    }
    &-black {
      color: $--clr-black !important;
    }
    &-white {
      color: $--clr-white !important;
    }
  }

  // HIGHLIGHT
  .highlighted {
    position: relative;
    display: inline-block;
    width: auto;
    &-wrap {
      position: relative;
      & > * {
        position: relative;
        display: inline-block;
        width: auto;
        margin: 0;
        inset: 0;
      }
    }
    span, b {
      position: relative;
      z-index: 1;
    }
    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 8rem;
      left: 0;
      bottom: 4px;
      border-radius: 4px;
      background-color: $--clr-point-1;
      opacity: 0.75;
      z-index: 0;
    }
  }

  // SWIPER
  .swiper {
    &-scrollbar {
      width: calc(100% - 24rem) !important;
      height: 3rem !important;
      left: 0 !important;
      background-color: rgba(0,0,0,0) !important;
      border-radius: 0 !important;
      border-top: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
      border-bottom: 1px solid $--clr-gray-3 !important;
      &-drag {
        height: 3rem !important;
        border-radius: 0 !important;
        background-color: $--clr-gray-3 !important;
      }
    }
    &-container {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  // FONT-MAIN
  .main {
    &-point {
      font-family: 'Konai', sans-serif;
      font-size: 74rem;
      font-weight: 400;
      line-height: 1.62;
      color: $--clr-point-1;
      text-align: center;
      word-break: keep-all;
      &.fs {
        &-80 {
          font-size: 80rem;
          font-weight: 400;
          line-height: 1.25;
        }
        &-56 {
          font-size: 42rem;
          font-weight: 400;
          line-height: 1.28;
        }
      }
    }
    &-subpoint {
      font-family: 'Konai', sans-serif;
      font-size: 28rem;
      font-weight: 400;
      line-height: 1.28;
      color: $--clr-black;
      text-align: center;
      word-break: keep-all;
    }
    &-title {
      font-family: 'Pretendard', sans-serif;
      font-size: 42rem;
      font-weight: 700;
      line-height: 1.28;
      color: $--clr-black;
      text-align: center;
      word-break: keep-all;
      &.fs {
        &-50 {
          font-size: 50rem;
          font-weight: 700;
          line-height: 1.12;
        }
      }
    }
    &-mdtitle {
      font-family: 'Pretendard', sans-serif;
      font-size: 40rem;
      font-weight: 700;
      line-height: 1.4;
      color: $--clr-black;
      text-align: center;
      word-break: keep-all;
    }
    &-subtitle {
      font-family: 'Pretendard', sans-serif;
      font-size: 32rem;
      font-weight: 700;
      line-height: 1.5;
      color: $--clr-black;
      text-align: center;
      word-break: keep-all;
      &.fs {
        &-30 {
          font-size: 30rem;
          font-weight: 700;
          line-height: 1.4;
        }
        &-28 {
          font-size: 28rem;
          font-weight: 700;
          line-height: 1.29;
        }
      }
    }
    &-smtitle {
      font-family: 'Pretendard', sans-serif;
      font-size: 24rem;
      font-weight: 600;
      line-height: 1.5;
      color: $--clr-black;
      text-align: center;
      word-break: keep-all;
    }
    &-text {
      font-family: 'Pretendard', sans-serif;
      font-size: 18rem;
      font-weight: 400;
      line-height: 1.67;
      letter-spacing: -0.2rem;
      color: $--clr-black;
      text-align: center;
      word-break: keep-all;
      &.fs {
        &-23 {
          font-size: 23rem;
          font-weight: 700;
          line-height: 1.48;
        }
      }
    }
  }

  // BODY FRAME
  .header.fixed {
    & + .frame-container {
      //height: 620px;
      margin-top: 80px;
    }
  }
  .frame {
    &-container {
      position: relative;
      width: 100%;
      height: auto;
      padding: 0;
      z-index: 5;
      &-text {
        position: absolute;
        display: flex;
        width: auto;
        height: 620px;
        //margin-top: -40px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        & > *:first-child:last-child {
          margin: auto 0;
        }
        & > *:first-child:not(:last-child) {
          margin: auto 0 0;
        }
        & > *:last-child:not(:first-child) {
          margin: 0 0 auto;
        }
      }
    }
    &-subpoint {
      font-family: 'Konai', sans-serif;
      font-size: 45rem;
      font-weight: 400;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      text-transform: capitalize;
      color: $--clr-white;
      word-break: keep-all;
      &:not(:last-child) {
        margin-bottom: 20rem;
      }
    }
    &-title {
      font-family: 'Pretendard', sans-serif;
      font-size: 72rem;
      font-weight: 700;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: $--clr-white;
      word-break: keep-all;
      white-space: nowrap;
    }
    &-contents {
      min-height: calc(100% - 214rem);
      &.for_terms {
        position: relative;
        display: inline-flex;
        width: 100%;
        height: auto;
        min-height: calc(100vh - 294px);
        margin-top: 80px;
      }
      &__inner {
        position: relative;
        display: flex;
        width: 100%;
        height: auto;
        margin: 0;
        //padding: 72rem 200px;
        padding: 72rem calc(100vw * 0.104167);
        z-index: 5;
        //&:nth-child(2n-1) {
        //  background-color: $--clr-white;
        //}
        //&:nth-child(2n) {
        //  background-color: $--clr-white;
        //}
        &:not(:first-child) {
          margin-top: 40px;
        }
        & > * {
          &:first-child:last-child {
            max-width: 1024rem;
            margin: 0 auto;
          }
        }
        &.with_img {
          & > * {
            &:first-child:last-child {
              display: flex;
              align-items: flex-start;
              & > * {
                &:not(:first-child) {
                  margin-left: 100rem;
                }
              }
            }
            .img_wrap {
              width: auto;
              height: auto;
              img {
                display: block;
                width: 550rem;
                height: 420rem;
                border-radius: 12rem;
                object-fit: cover;
                overflow: hidden;
              }
            }
            .text_wrap {
              text-align: left;
              .main {
                &-mdtitle {
                  text-align: left;
                  &:not(:last-child) {
                    margin-bottom: 20rem;
                  }
                }
                &-text {
                  text-align: left;
                }
              }
            }
          }
        }
        .main {
          &-title {
            &:not(:last-child) {
              margin-bottom: 50rem;
            }
          }
          &-text {
            &:not(:last-child) {
              margin-bottom: 50rem;
            }
          }
        }
      }
    }
  }

  // BUTTON
  .btn {
    font-family: 'Pretendard', sans-serif;
    display: inline-block;
    width: auto;
    height: auto;
    &-md {
      max-height: 50px;
      padding: 14px 24px;
      font-size: 18rem;
      font-weight: 600;
      // line-height: 1.22;
      line-height: 1;
      text-align: center;
      word-break: keep-all;
      &.round {
        border-radius: 50px;
      }
    }
    &-sm {
      max-height: 39px;
      padding: 10px 20px;
      font-size: 14rem;
      font-weight: 600;
      // line-height: 1.35;
      line-height: 1;
      text-align: center;
      word-break: keep-all;
      &.round {
        border-radius: 39px;
      }
    }
    &-shortcut {
      width: 48px;
      height: 48px;
      border-radius: 48px;
      background-image: var(--icon-shortcut-w);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      &.btn-sm, &.btn-md {
        display: flex;
        align-items: center;
        width: auto;
        height: 43px;
        padding: 0 15rem;
        &:after {
          content: '';
          display: inline-block;
          width: 12rem;
          height: 12rem;
          margin-left: 5rem;
          background-image: var(--icon-shortcut-r);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
    &-arrow {
      width: auto;
      height: auto;
      padding: 0;
      color: $--clr-point-1;
      text-align: left;
      &::after {
        content: '';
        display: inline-block;
        width: 9px;
        height: 13px;
        margin: 0 0 -1px 7px;
        background-image: var(--icon-arr-rt-r);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      &.ico-only {
        &::before, &::after {
          display: none;
        }
        &.for_main {
          position: absolute;
          display: block;
          width: 30rem;
          height: 15rem;
          bottom: calc(8.15vh - 15rem);
          left: 50%;
          transform: translate(-50%, 0);
          background-image: var(--icon-arr-dn-w);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          //pointer-events: auto;
          //cursor: pointer;
          pointer-events: none;
          cursor: default;
          opacity: 0;
          animation: mainReveal 0.5s 0.3s ease-out forwards, mainArrow 3s 0.8s ease-in-out infinite;
        }
      }
    }
    &.point {
      background-color: $--clr-point-1;
      color: $--clr-white;
    }
    &.white {
      background-color: $--clr-white;
      color: $--clr-point-1;
    }
    &.darkgray {
      background-color: $--clr-gray-3;
      color: $--clr-white;
    }
    &.gray {
      background-color: $--clr-gray-5;
      color: $--clr-white;
    }
    &.line {
      background-color: transparent;
      border: 1px solid transparent;
      &.point {
        border-color: $--clr-point-1;
        color: $--clr-point-1;
      }
      &.gray {
        border-color: $--clr-gray-5;
        color: $--clr-gray-5;
      }
    }
  }

  // TAG
  .tag {
    display: inline-block;
    width: auto;
    height: auto;
    padding: 10rem 16rem;
    font-size: 16rem;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: -0.48px;
    text-align: center;
    word-break: keep-all;
    text-transform: capitalize;
    border-radius: 50px;
    &-wrap {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: auto;
      .tag {
        padding: 5px 10px;
        font-weight: 600;
        cursor: pointer;
        pointer-events: auto;
        &:not(:last-child) {
          margin-right: 8rem;
        }
        &.line {
          color: $--clr-gray-3;
          border-color: $--clr-gray-3;
        }
        &.active {
          color: $--clr-white;
          background-color: $--clr-point-1;
          border: 1px solid $--clr-point-1;
        }
      }
    }
    &.small {
      padding: 6rem 16rem;
    }
    &.point {
      background-color: $--clr-point-1;
      color: $--clr-white;
    }
    &.point2 {
      background-color: $--clr-point-2;
      color: $--clr-white;
    }
    &.point3 {
      background-color: $--clr-point-3;
      color: $--clr-white;
    }
    &.point4 {
      background-color: rgba(49,163,201,0.1);
      color: $--clr-point-2;
    }
    &.white {
      background-color: $--clr-white;
      color: $--clr-black;
    }
    &.gray {
      background-color: $--clr-gray-5;
      color: $--clr-white;
    }
    &.black {
      background-color: $--clr-black;
      color: $--clr-white;
    }
    &.line {
      background-color: transparent;
      border: 1px solid transparent;
      &-point-1 {
        border-color: $--clr-point-1;
        color: $--clr-point-1;
      }
      &-gray {
        border-color: $--clr-gray-5;
        color: $--clr-gray-5;
      }
      &-darkgray {
        border-color: $--clr-gray-1;
        color: $--clr-gray-1;
      }
      &-black {
        border-color: $--clr-black;
        color: $--clr-black;
      }
    }
  }

  // BOX
  .block_box {
    width: auto;
    height: auto;
    margin: 0;
    padding: 32px;
    &.with_shadow {
      box-shadow: 0 4px 12px rgba(0,0,0,0.08);
    }
    &.point {
      background-color: $--clr-point-1;
      color: $--clr-white;
    }
    &.point-2 {
      background-color: $--clr-point-2;
      color: #375C72;
    }
    &.point-3 {
      background-color: rgba(248,183,61,0.12);
      color: #F8B73D;
    }
    &.black {
      background-color: $--clr-black;
      color: $--clr-white;
    }
    &.lightgray {
      background-color: $--clr-lightgray-4;
      color: $--clr-black;
    }
    &.white {
      background-color: $--clr-white;
      color: $--clr-black;
    }
    &.r {
      &-12 {
        border-radius: 12px;
      }
      &-16 {
        border-radius: 16px;
      }
      &-24 {
        border-radius: 24px;
      }
    }
    .tab-contents {
      background-color: unset !important;
    }
  }

  // TAB
  .tab {
    &-wrap {
      //position: relative;
      position: sticky;
      display: flex;
      align-items: flex-end;
      width: 100%;
      height: 100px;
      max-height: 100px;
      top: 0;
      text-align: center;
      background-color: $--clr-white;
      border-bottom: 1px solid $--clr-gray-5;
      z-index: 6;
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 80px;
        top: -1px;
        left: 0;
        z-index: -1;
        background-color: $--clr-white;
        opacity: 1;
        pointer-events: none;
      }
      &.fixed {
        transform: translate(0, 80rem);
        transition: transform 0.3s 0.15s ease-in-out;
        &:before {
          transform: translate(0, -80px);
          z-index: -1;
          opacity: 1;
          pointer-events: auto;
          transition: all 0.15s 0.15s ease-in-out;
        }
      }
      &.inner {
        height: auto;
      }
      &__inner {
        width: auto;
        height: auto;
        margin: 0 auto;
      }
    }
    &-contents {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      min-height: calc(100% - 214rem);
      z-index: 5;
      &__inner {
        position: relative;
        display: none;
        width: 100%;
        max-width: 1024px;
        height: auto;
        margin: 0 auto;
        padding: 152rem 0 80rem;
      }
    }
    &-list {
      display: flex;
      width: auto;
      height: 100%;
    }
    &-elem {
      display: block;
      width: auto;
      height: auto;
      margin: 0 24rem;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      & > a {
        pointer-events: auto;
        label {
          display: flex;
          flex-direction: column;
          font-family: 'Pretendard', sans-serif;
          font-size: 18rem;
          font-weight: 400;
          line-height: normal;
          text-align: center;
          text-transform: capitalize;
          color: $--clr-gray-1;
          pointer-events: none;
          cursor: pointer;
          transition: color 0.3s ease-in-out;
          &:after {
            content: '';
            display: block;
            position: relative;
            width: 100%;
            max-width: 0;
            height: 2rem;
            min-height: 2px;
            margin-top: 24rem;
            background-color: $--clr-white-trans;
            transition: max-width 0.3s ease-in-out;
          }
        }
        &.active {
          label {
            font-weight: 700;
            color: $--clr-point-1;
            transition: color 0.3s ease-in-out;
            &:after {
              max-width: 100%;
              background-color: $--clr-point-1;
              transition: max-width 0.3s ease-in-out;
            }
          }
        }
      }
    }
    &-input {
      display: none;
      &:checked {
        & + label {
          font-weight: 700;
          color: $--clr-point-1;
          &:after {
            max-width: 100%;
            background-color: $--clr-point-1;
          }
        }
      }
    }
    &-label {
      display: flex;
      flex-direction: column;
      font-family: 'Pretendard', sans-serif;
      font-size: 18rem;
      font-weight: 400;
      line-height: normal;
      text-align: center;
      text-transform: capitalize;
      color: $--clr-gray-1;
      pointer-events: auto;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:after {
        content: '';
        display: block;
        position: relative;
        width: 100%;
        max-width: 0;
        height: 2rem;
        min-height: 2px;
        margin-top: 24rem;
        background-color: $--clr-white-trans;
        transition: max-width 0.3s ease-in-out;
      }
    }
    &-title {
      font-family: 'Pretendard', sans-serif;
      font-size: 40rem;
      font-weight: 800;
      line-height: 1;
      color: $--clr-black;
      //margin: 0 0 72rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      white-space: normal;
      & + .tab-subtitle {
        margin: 64rem 0 40rem;
      }
      & + .tab-text {
        margin: 64rem 0 40rem;
      }
      & + .board-wrap, & + .addservice-wrap {
        margin-top: 72rem;
      }
    }
    &-subtitle {
      font-family: 'Pretendard', sans-serif;
      font-size: 24rem;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: -0.72px;
      color: $--clr-gray-2;
      margin: 0 0 40rem;
    }
    &-text {
      font-family: 'Pretendard', sans-serif;
      font-size: 18rem;
      font-weight: 600;
      line-height: normal;
      color: $--clr-gray-1;
      margin: 0 0 72rem;
    }
  }

  // TABLE
  .table {
    &-wrap {
      display: block;
      //width: 100%;
      width: calc(100% + 24rem);
      height: auto;
      overflow-x: auto;
      &:not(:first-child) {
        margin-top: 72rem;
      }
    }
    &-title {
      display: flex;
      align-items: center;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
      font-size: 20rem;
      font-weight: 700;
      line-height: 1;
      text-align: left;
      word-break: keep-all;
      white-space: nowrap;
      color: $--clr-black;
      .unit {
        font-size: 18rem;
        color: $--clr-gray-3;
      }
      &.absolute {
        position: absolute;
        width: calc(100% - 48rem);
        & + .table-elem {
          &:not(:first-child) {
            margin-top: 45rem;
          }
        }
      }
    }
    &-elem {
      position: relative;
      display: table;
      width: calc(100% - 24rem);
      height: auto;
      margin-right: 24rem;
      &:not(:first-child) {
        margin-top: 25rem;
      }
      &.list {
        tr {
          &:first-child {
            td {
              border-top: 1px solid $--clr-gray-4;
            }
          }
          td {
            padding: 32rem 20rem;
            text-align: center;
            &:not(:first-child):not(:last-child) {
              padding: 0;
              text-align: left;
            }
          }
        }
        a {
          &.tag {
            display: flex;
            align-items: center;
            width: auto;
            height: 34rem;
            padding: 10rem 15rem;
            border-radius: 34rem;
            font-size: 14rem;
            font-weight: 500;
            line-height: 1;
            text-align: center;
          }
          &[onclick] {
            font-size: 20rem;
            font-weight: 600;
            line-height: 1.7;
            color: $--clr-black;
            text-align: left;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            word-break: break-all;
          }
        }
        .date {
          font-size: 20rem;
          font-weight: 400;
          line-height: 1.8;
          color: $--clr-gray-3;
        }
      }
      * {
        font-family: 'Pretendard', sans-serif;
        text-align: center;
        vertical-align: middle;
      }
      thead {
        tr {
          th {
            background-color: $--clr-lightgray-3;
            border-top: 1px solid $--clr-black;
            border-bottom: 1px solid $--clr-gray-4;
            &[rowspan] {
              &:not(:last-child) {
                border-right: 1px solid $--clr-gray-4;
              }
            }
            &.depth_2 {
              background-color: $--clr-white;
              &:not(:last-child) {
                border-right: 1px solid $--clr-gray-4;
              }
            }
          }
        }
      }
      tbody {
        &.with_line {
          tr {
            td {
              &:not(:last-child) {
                border-right: 1px solid $--clr-gray-4;
              }
              &:not(:first-child) {
                border-left: 1px solid $--clr-gray-4;
              }
            }
          }
        }
        tr {
          th {
            border-bottom: 1px solid $--clr-gray-4;
          }
        }
      }
      tr {
        th {
          display: table-cell;
          width: auto;
          height: auto;
          margin: 0;
          padding: 15px 20px;
          font-size: 16rem;
          font-weight: 700;
          //line-height: 2.12;
          line-height: normal;
          word-break: keep-all;
          white-space: nowrap;
          color: $--clr-black;
        }
        td {
          display: table-cell;
          width: auto;
          height: auto;
          margin: 0;
          padding: 15px 20px;
          font-size: 15rem;
          font-weight: 400;
          //line-height: 2.4;
          line-height: normal;
          word-break: keep-all;
          color: $--clr-black;
          border-bottom: 1px solid $--clr-gray-4;
          &.number {
            text-align: right;
            &.wd80 {
              .flex {
                & > span {
                  max-width: 80px;
                }
              }
            }
            &.wd140 {
              .flex {
                & > span {
                  max-width: 140px;
                }
              }
            }
            .flex {
              & > span {
                width: 100%;
                text-align: right;
                &:first-child:last-child {
                  margin: 0 auto;
                }
                &:first-child:not(:last-child) {
                  margin: 0 0 0 auto;
                }
                &:last-child:not(:first-child) {
                  margin: 0 auto 0 0;
                }
              }
            }
          }
        }
      }
    }
  }

  // ACCORDION
  .accordion {
    &-wrap{
      position: relative;
      display: inline-block;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
    }
    &-elem {
      position: relative;
      display: inline-block;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
      border-bottom: 1px solid $--clr-lightgray-2;
      & > a {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: auto;
        min-height: 62rem;
        margin: 0;
        padding: 15rem 24rem;
        font-size: 13rem;
        font-weight: 400;
        line-height: 1.77;
        letter-spacing: normal;
        color: $--clr-black;
        pointer-events: auto;
        cursor: pointer;
        &::after {
          content: '';
          position: relative;
          display: inline-block;
          width: 12rem;
          min-width: 12rem;
          height: 16rem;
          margin: 0 0 0 16rem;
          background-image: var(--icon-arr-dn-b);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
        & > span {
          &:not(.sr-only) {
            display: inline-flex;
            align-items: center;
            width: 100%;
            word-break: keep-all;
            &:before {
              content: '';
              width: 24rem;
              min-width: 24rem;
              height: 24rem;
              margin-right: 16rem;
              background-image: var(--icon-faq-q);
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              transition: all 0.2s ease-in-out;
            }
          }
        }
        &.active {
          font-weight: 700;
          &::after {
            background-image: var(--icon-arr-up-b);
          }
          & > span {
            &:not(.sr-only) {
              &:before {
                background-image: var(--icon-faq-q-on);
              }
            }
          }
          & + div {
            max-height: 5000rem;
            transition: all 0.5s ease-in-out;
          }
        }
        & + div {
          position: relative;
          display: block;
          width: 100%;
          height: auto;
          max-height: 0;
          margin: 0;
          padding: 0;
          background-color: #F1F4F9;
          transition: max-height 0.15s cubic-bezier(0, 1, 0, 1);
          overflow: hidden;
          & > .wrap {
            display: inline-flex;
            width: 100%;
            height: auto;
            margin: 0;
            padding: 15rem 24rem;
            font-size: 13rem;
            font-weight: 400;
            line-height: 1.77;
            letter-spacing: normal;
            color: $--clr-black;
            &:before {
              content: '';
              display: inline-block;
              width: 24rem;
              min-width: 24rem;
              height: 24rem;
              margin-right: 16rem;
              background-image: var(--icon-faq-a);
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              transition: all 0.2s ease-in-out;
            }
          }
          & > .wrap .dot_list .list_elem + .list_elem{
            margin-top: 16rem;
          }
        }
      }
    }
  }


  // BOARD-DETAIL
  .detail {
    &-wrap {
      display: flex;
      width: 100%;
      height: auto;
      padding: 22rem 16rem;
      border-top: 2px solid #555;
      border-bottom: 1px solid $--clr-lightgray-2;
      &:not(:first-child) {
        margin-top: 74rem;
      }
      & ~ .board-wrap {
        padding: 32rem 16rem 0;
        .board-attachment {
          width: calc(100% + 32rem);
          margin-left: -16rem;
          margin-right: -16rem;
        }
      }
    }
    &-title {
      display: inline-block;
      font-size: 16rem;
      font-weight: 700;
      line-height: 1.25;
      letter-spacing: -0.48px;
      color: $--clr-black;
      text-align: left;
    }
    &-text {
      display: inline-block;
      margin-left: auto;
      font-size: 13rem;
      font-weight: 400;
      line-height: 1.77;
      letter-spacing: normal;
      color: $--clr-black;
      text-align: right;
    }
  }

  // BOARD
  .board {
    &-wrap {
      display: block;
      width: 100%;
      height: auto;
      padding: 0;
      & > .accordion-wrap {
        margin-top: 0 !important;
      }
      & + .board-navigator {
        margin-top: 32rem;
      }
      .img_wrap {
        width: 100%;
        height: auto;
        max-height: 196px;
      }
      &.media, &.bigdata {
        padding: 0;
        .board-title {
          display: none;
        }
        .media_grid, .bigdata_grid {
          ul {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: auto;
            & > li {
              flex: 1 1 40%;
              &:not(:nth-child(1)), &:not(:nth-child(2)) {
                margin-top: 16rem;
              }
              & > .flex.vert {
                .video, .image {
                  position: relative;
                  display: inline-block;
                  width: 100%;
                  height: auto;
                  border-radius: 16rem;
                  overflow: hidden;
                  iframe, a {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 282rem;
                    img {
                      display: inline-block;
                      width: 100%;
                      height: 100%;
                      object-fit: contain;
                    }
                  }
                }
                .info {
                  position: relative;
                  display: inline-block;
                  width: 100%;
                  height: auto;
                  padding: 16rem;
                  .title {
                    font-size: 24rem;
                    font-weight: 700;
                    line-height: 1.5;
                    letter-spacing: -0.72px;
                    text-align: left;
                    color: $--clr-black;
                    &:not(:last-child) {
                      margin-bottom: 4px;
                    }
                  }
                  .title_tag {
                    font-size: 13rem;
                    font-weight: 400;
                    line-height: 1.77;
                    letter-spacing: normal;
                    text-align: left;
                    color: $--clr-gray-5;
                  }
                }
              }
              &:nth-child(2n-1) {
                width: min(502rem, calc(50% - 10px));
                max-width: min(502rem, calc(50% - 10px));
                margin-right: 10px;
                & > .flex.vert {
                  align-items: flex-end;
                }
              }
              &:nth-child(2n) {
                width: min(502rem, calc(50% - 10px));
                max-width: min(502rem, calc(50% - 10px));
                margin-left: 10px;
                & > .flex.vert {
                  align-items: flex-start;
                }
              }
            }
          }
        }
      }
      .for_pc {
        display: table-column;
      }
    }
    &-img {
      width: 100%;
      max-width: 306px;
      height: 196rem;
      max-height: 196px;
      border-radius: 12rem;
      overflow: hidden;
      object-fit: cover;
    }
    &_img {
      display: inline-block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      &-wrap {
        display: flex;
        justify-content: center;
        width: 100%;
        height: auto;
        max-height: 534px;
        padding: 0 100rem;
        &:not(:last-child) {
          margin-bottom: 95rem;
        }
      }
    }
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
      font-size: 18rem;
      font-weight: 700;
      line-height: 1;
      text-align: left;
      word-break: keep-all;
      white-space: nowrap;
      color: $--clr-black;
      .unit {
        font-size: 18rem;
        color: $--clr-gray-3;
      }
      &[style*='display: none'] ~ *[style*='display: block'], &[style*='display: none'] ~ *[style*='display: table'] {
        margin-top: 66rem;
      }
    }
    &-txt {
      font-size: 18rem;
      font-weight: 700;
      line-height: 1;
      text-align: left;
      word-break: keep-all;
      white-space: nowrap;
      color: $--clr-black;
    }
    &-elem {
      display: table;
      table-layout: fixed;
      width: 100%;
      height: auto;
      &:not(:first-child) {
        margin: 16rem 0 0;
      }
      &.with_img {
        & + .pagination-wrap {
          margin-top: 90rem;
        }
        tr {
          td {
            padding: 50rem 20rem;
            & > span {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              white-space: normal;
            }
            & > p {
              text-align: left;
              &.date {
                margin-top: 20rem;
                font-size: 22rem;
                color: $--clr-gray-3;
              }
            }
          }
        }
      }
      * {
        font-family: 'Pretendard', sans-serif;
        text-align: center;
        vertical-align: middle;
      }
      &.no_result {
        thead {
          tr {
            &:first-child:last-child {
              th, td {
                //border-top: 1px solid $--clr-gray-4;
                border-bottom: 0;
                padding: 0;
                .flex.vert {
                  min-height: 230rem;
                  padding-top: 72rem;
                  & > *:first-child {
                    margin-top: auto;
                  }
                  & > *:last-child {
                    margin-bottom: auto;
                  }
                  & > span {
                    font-size: 23rem;
                    font-weight: 700;
                  }
                  .no {
                    &-results, &-store_list, &-preparing {
                      display: inline-flex;
                      flex-direction: column;
                      align-items: center;
                      *, .board-txt {
                        position: relative;
                        display: block;
                        font-size: 16rem;
                        font-weight: 400;
                        line-height: 1.25;
                        letter-spacing: normal;
                        color: $--clr-black;
                        span + span {
                          margin-top: 4px;
                        }
                      }
                      &:before {
                        content: '';
                        position: relative;
                        display: block;
                        width: 72rem;
                        min-width: 72rem;
                        height: 72rem;
                        margin-bottom: 8rem;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                      }
                    }
                    &-results {
                      &:before {
                        display: none;
                      }
                    }
                    &-store_list {
                      &:before {
                        background-image: var(--icon-nolist);
                      }
                    }
                    &-preparing {
                      &:before {
                        background-image: var(--icon-preparing);
                      }
                    }
                  }
                  .board-txt {
                    color: $--clr-gray-3;
                    font-weight: 400;
                    &:not(:first-child) {
                      margin-top: 13rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
      thead {
        tr {
          th {
            background-color: $--clr-white;
            border-top: 2px solid #555;
            border-bottom: 1px solid $--clr-lightgray-2;
          }
        }
        & + tbody {
          tr {
            &:first-child {
              th, td {
                border-top: 0;
              }
            }
          }
        }
      }
      tbody {
        tr {
          * {
            &[onclick] {
              pointer-events: auto;
              cursor: pointer;
            }
          }
          &:first-child {
            th, td {
              border-top: 2px solid #555;
            }
          }
          td {
            border-bottom: 1px solid $--clr-lightgray-2;
            transition: color 0.15s ease-in-out;
            &:hover {
              &:not(.date):not(a):not(button):not(.center) {
                color: $--clr-point-1;
                transition: color 0.3s ease-in-out;
              }
            }
            .tag {
              display: block;
              max-width: fit-content;
              padding: 10rem 14rem;
              border-radius: 40rem;
              font-size: 14rem;
              pointer-events: none;
              cursor: default;
              &.line {
                border-color: $--clr-gray-1;
                color: $--clr-gray-1;
              }
              &:not(:last-child) {
                margin-bottom: 16rem;
              }
            }
          }
        }
      }
      tr {
        th {
          display: table-cell;
          width: auto;
          height: auto;
          margin: 0;
          padding: 16rem 13rem;
          font-size: 13rem;
          font-weight: 400;
          line-height: 1.77;
          text-align: left;
          word-break: keep-all;
          color: $--clr-black;
        }
        td {
          display: table-cell;
          width: auto;
          //min-width: 76px;
          height: auto;
          margin: 0;
          padding: 16rem 13rem;
          font-size: 13rem;
          font-weight: 400;
          line-height: 1.77;
          text-align: left;
          word-break: break-all;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color: $--clr-black;
          &.pin {
            max-width: 100px;
            font-size: 13rem;
            font-weight: 400;
            line-height: 1.77;
            white-space: nowrap;
            overflow: unset;
            color: $--clr-black;
            span {
              display: inline-block;
              &:first-child:last-child {
                width: 16rem;
                min-width: 16rem;
                height: 16rem;
                background-image: var(--icon-pin);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
              }
            }
          }
          &.date, .date {
            max-width: 160px;
            font-size: 13rem;
            font-weight: 400;
            line-height: 1.77;
            white-space: nowrap;
            overflow: unset;
            color: $--clr-gray-5;
          }
          &:not(.center):not(.date) {
            a, span:not(.date), span > strong {
              //display: block;
              width: 100%;
              text-align: left;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
              & ~ span {
                margin-left: auto;
              }
            }
          }
        }
      }
    }
    &_cont {
      &:not(:first-child) {
        margin: 16rem 0 0;
      }
    }
    &-detail {
      display: block;
      width: 100%;
      height: auto;
      font-size: 16rem;
      font-weight: 400;
      line-height: 1.63;
      text-align: left;
      color: $--clr-black;
      * {
        background-color: unset !important;
        background: unset !important;
      }
      img {
        //width: auto;
        width: 50% !important;
        max-width: 100%;
        height: auto !important;
        max-height: unset;
        object-fit: contain;
        //margin-bottom: 20rem;
      }
      a[href] {
        display: inline-flex;
        align-items: center;
        &:hover {
          text-decoration: underline;
        }
        &:after {
          content: "";
          display: inline-block;
          width: 14px;
          min-width: 14px;
          height: 14px;
          min-height: 14px;
          margin-left: 4px;
          background-image: var(--icon-shortcut);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      iframe, video {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 534px;
        z-index: 10;
      }
      figure {
        display: block;
        margin: 0;
        line-height: 1;
        &.image {
          display: inline-block;
          width: 100% !important;
          max-width: 100%;
          height: auto !important;
          max-height: unset;
          text-align: center;
          figcaption {
            font-size: 14rem;
          }
        }
        &.media, &.bigdata, iframe, video, image {
          position: relative;
          display: inline-block;
          width: 100%;
          height: 534px;
          z-index: 10;
        }
        &.table {
          width: 100%;
          table {
            width: 100%;
            tr {
              &:first-child {
                th, td {
                  border-top: 1px solid $--clr-black;
                }
              }
              &:not(:first-child) {
                th, td {
                  border-top: 1px solid $--clr-gray-4;
                }
              }
              &:last-child {
                th, td {
                  border-bottom: 1px solid $--clr-gray-4;
                }
              }
            }
            th {
              width: auto;
              height: 60px;
              padding: 15px 20px;
              font-size: 16px;
              font-weight: 700;
              line-height: normal;
              letter-spacing: normal;
              vertical-align: middle;
              background-color: $--clr-lightgray-3;
              color: $--clr-black;
              text-align: center;
            }
            td {
              width: auto;
              height: 60px;
              padding: 15px 20px;
              //font-size: 15px;
              font-weight: 400;
              //line-height: normal;
              letter-spacing: normal;
              vertical-align: middle;
              background-color: $--clr-white;
              color: $--clr-black;
              //text-align: center;
              border-top: 1px solid $--clr-black;
            }
          }
        }
      }
    }
    &-attachment {
      width: 100%;
      padding: 32rem 16rem 0;
      border-top: 1px solid $--clr-lightgray-2;
      &:not(:first-child) {
        margin-top: 32rem;
      }
      & + .board-navigator {
        border-top: 0;
      }
      .attachment-elem {
        display: flex;
        align-items: center;
        width: auto;
        margin: 0;
        padding: 0;
        & + .attachment-elem {
          margin-top: 16rem;
        }
        & > span {
          font-size: 15rem;
          line-height: 1;
          font-weight: 500;
          color: $--clr-gray-1;
          &:hover, &:focus, &:active {
            color: $--clr-black;
          }
          &:last-child {
            margin: 0 auto 0 0;
          }
        }
        &:before {
          content: '';
          display: inline-block;
          width: 24rem;
          height: 24rem;
          margin: 0 8rem 0 0;
          background-image: var(--icon-attachment);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
    &-navigator {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: auto;
      padding-top: 40rem;
      border-top: 1px solid $--clr-lightgray-2;
      & > .flex {
        width: auto;
      }
      & > .btn {
        &:first-child:last-child {
          margin-left: auto;
          margin-right: auto;
        }
      }
      .btn {
        font-size: 16rem;
        font-weight: 400;
        line-height: 1;
        color: $--clr-black;
        &.navigator {
          &-list {
            width: 120rem;
            height: 56rem;
            padding: 18rem 24rem;
            border-radius: 8rem;
            background-color: $--clr-point-1;
            color: $--clr-white;
            font-weight: 700;
            letter-spacing: -0.48px;
          }
          &-prev {
            display: inline-flex;
            align-items: center;
            width: auto;
            height: auto;
            &.disabled, &:disabled {
              opacity: 0.3;
              pointer-events: none;
              cursor: default;
            }
            &:before {
              content: '';
              display: block;
              width: 25rem;
              height: 25rem;
              margin-right: 10rem;
              background-image: var(--btn-page-arr-lt);
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
          &-next {
            display: inline-flex;
            align-items: center;
            width: auto;
            height: auto;
            &.disabled, &:disabled {
              opacity: 0.3;
              pointer-events: none;
              cursor: default;
            }
            &:after {
              content: '';
              display: block;
              width: 25rem;
              height: 25rem;
              margin-left: 10rem;
              background-image: var(--btn-page-arr-rt);
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
    &-other {
      display: block;
      width: 100%;
      height: auto;
      margin: 93rem 0 0;
      &-title {
        padding: 0 100rem;
        font-size: 23rem;
        font-weight: 700;
        line-height: 1.47;
        color: $--clr-black;
        &:not(:last-child) {
          margin-bottom: 32rem;
        }
        &.list {
          padding: 0 20rem;
        }
      }
      &-text {
        font-size: 16rem;
        font-weight: 600;
        line-height: 1.43;
        color: $--clr-black;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        white-space: normal;
      }
      &-date {
        font-size: 16rem;
        font-weight: 400;
        line-height: 2.25;
        color: $--clr-gray-3;
      }
      &-img {
        display: inline-block;
        width: 100%;
        height: 200rem;
        border-radius: 12rem;
        object-fit: cover;
        overflow: hidden;
      }
      .img_wrap {
        display: block;
        width: 100%;
        height: auto;
        &:not(:last-child) {
          margin-bottom: 20rem;
        }
      }
      .swiper {
        padding: 0 100rem;
        &:before {
          content: '';
          position: absolute;
          display: block;
          width: 100rem;
          height: 100%;
          top: 0;
          left: 0;
          background: $--clr-white;
          z-index: 2;
        }
        &:after {
          content: '';
          position: absolute;
          display: block;
          width: 100rem;
          height: 100%;
          top: 0;
          right: 0;
          background: $--clr-white;
          z-index: 2;
        }
        &-button {
          &-prev {
            width: 32rem;
            height: 32rem;
            margin-right: 68rem;
            left: 0;
            background-image: var(--btn-nav-arr-lt);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            transform: translate(0, -47rem);
            opacity: 0.49;
            z-index: 3;
            &:after {
              display: none;
            }
          }
          &-next {
            width: 32rem;
            height: 32rem;
            margin-left: 68rem;
            right: 0;
            background-image: var(--btn-nav-arr-rt);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            transform: translate(0, -47rem);
            opacity: 0.49;
            z-index: 3;
            &:after {
              display: none;
            }
          }
          &-disabled {
            opacity: 0.15 !important;
          }
        }
        &-slide {
          & > a {
            .board-other-text {
              transition: color 0.3s ease-in-out;
            }
          }
          &:hover {
            & > a {
              .board-other-text {
                color: $--clr-point-1;
              }
            }
          }
        }
        &-pagination {
          position: absolute;
          display: none;
        }
      }
    }
  }

  // PAGINATION
  .pagination {
    &-wrap {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      height: auto;
      padding: 24px 0 16px;
      //margin-top: 204rem;
    }
    &-btn {
      display: block;
      //width: 21rem;
      //height: 21rem;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      & + .pagination-btn {
        //margin-left: 15rem;
      }
      &.toFront {
        background-image: var(--btn-page-arr-first);
      }
      &.toPrev {
        background-image: var(--btn-page-arr-lt);
      }
      &.toNext {
        background-image: var(--btn-page-arr-rt);
      }
      &.toBack {
        background-image: var(--btn-page-arr-last);
      }
    }
    &-list {
      display: block;
      width: auto;
      height: auto;
      margin: 0 8px;
    }
    &-elem {
      display: inline-block;
      //width: 21rem;
      //height: 21rem;
      * {
        //font-size: 16rem;
        //font-weight: 600;
        line-height: 1;
        color: $--clr-gray-3;
      }
      &.active, .active {
        * {
          color: $--clr-point-1;
        }
      }
      button, a {
        display: inline-flex;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        pointer-events: auto;
        cursor: pointer;
        span {
          margin: auto;
          pointer-events: none;
        }
      }
      & + .pagination-elem {
        margin-left: 8px;
      }
    }
  }

  // SELECT
  .select-wrap:not(.for_footer) {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    overflow: visible;
    .select {
      &-input {
        width: 100%;
        height: auto;
        padding: 15rem 20rem;
        background-color: $--clr-white;
        color: $--clr-black;
        border: 1px solid $--clr-gray-4;
        border-radius: 4px;
        outline: 0;
        font-size: 16rem;
        font-weight: 400;
        //line-height: 1.63;
        line-height: 1;
        &.hidden {
          display: none;
        }
      }
    }
    .btn {
      &-input {
        display: none;
        position: absolute;
        width: 0;
        height: 0;
        &:checked {
          & + .btn-select {
            border-radius: 4px 4px 0 0;
            &::after {
              background-image: var(--icon-arr-dn-b);
            }
          }
          & ~ .option-list {
            max-height: 800px;
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
      &-select {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: auto;
        padding: 15rem 20rem;
        background-color: $--clr-white;
        color: $--clr-black;
        border: 1px solid $--clr-gray-4;
        border-radius: 4px;
        outline: 0;
        font-size: 16rem;
        font-weight: 400;
        //line-height: 1.63;
        line-height: 1;
        word-break: keep-all;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        &::after {
          content: '';
          display: inline-block;
          width: 13px;
          min-width: 13px;
          height: 9px;
          margin-left: 4px;
          background-image: var(--icon-arr-dn-b);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
    .option-list {
      position: absolute;
      display: block;
      width: inherit;
      max-width: 371rem;
      height: auto;
      max-height: 0;
      //margin-top: 47rem;
      background-color: $--clr-white;
      color: $--clr-black;
      border: 1px solid $--clr-gray-4;
      border-radius: 0 0 4px 4px;
      transform: translate(0, 47rem);
      opacity: 0;
      overflow: hidden;
      pointer-events: none;
      transition: max-height 0.5s ease-in-out;
      .btn {
        &-option {
          width: 100%;
          height: auto;
          max-height: 48rem;
          padding: 15rem 20rem;
          outline: 0;
          font-size: 16rem;
          font-weight: 400;
          //line-height: 1.63;
          line-height: 1;
          word-break: keep-all;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          & > a {
            display: inline-block;
            width: 100%;
            span {
              pointer-events: none;
            }
          }
          &:not(:first-child) {
            border-top: 1px solid $--clr-gray-5;
          }
        }
      }
    }
  }

  // INPUT SEARCH
  .search {
    &-wrap {
      display: block;
      position: relative;
      width: auto;
      height: auto;
      .btn-search {
      }
    }
    &-elem {
    }
    &-input {
      &::placeholder {
        font-size: 13px;
        font-weight: 400;
        color: #E0E0E0;
      }
    }
  }

  // TITLE 관련
  .title {
    &-detail {
      display: flex;
      align-items: center;
      width: auto;
      height: auto;
      vertical-align: middle;
      &:not(:last-child) {
        margin-bottom: 25rem;
      }
      & > span {
        display: inline-flex;
        align-items: center;
        font-size: 18rem;
        font-weight: 400;
        line-height: 1.67;
        letter-spacing: -0.2rem;
        white-space: nowrap;
        color: $--clr-gray-3;
        &:not(:first-child) {
          &:before {
            content: '';
            display: inline-block;
            width: 1px;
            height: 50%;
            min-height: 15rem;
            margin: auto 15rem;
            padding: 0;
            background-color: $--clr-gray-3;
          }
        }
      }
    }
  }

  // LIST
  .dot_list {
    display: inline-block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    & > .list_elem {
      display: inline-block;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0 0 0 10rem;
      font-size: 13rem;
      font-weight: 400;
      line-height: 1.77;
      letter-spacing: normal;
      color: $--clr-gray-2;
      &:before {
        content: '';
        position: absolute;
        display: inline-block;
        width: 3px;
        min-width: 3px;
        height: 3px;
        margin: 9px 0 0 -8px;
        border-radius: 3px;
        background-color: $--clr-gray-2;
      }
    }
  }

  // ADDSERVICE
  .addservice {
    &-wrap {
      position: relative;
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;
      height: auto;
      & > * {
        flex: 1 1 50%;
        width: 100%;
        min-height: 184rem;
        margin: 0;
        &.block_box {
          display: inline-flex;
          flex-direction: column;
          padding: 32rem;
        }
        .img_wrap {
          position: relative;
          display: inline-block;
          width: auto;
          height: auto;
          margin: 0;
          margin: 0;
          img {
            position: relative;
            display: inline-block;
            object-fit: contain;
          }
        }
      }
      a:not(.tag), button {
        display: inline-block;
        width: auto;
        height: auto;
        font-size: 16rem;
        font-weight: 400;
        line-height: 1.25;
        letter-spacing: normal;
        color: $--clr-black;
        &:hover {
          text-decoration: underline;
        }
        &.btn {
          display: inline-block;
          width: auto;
          height: auto;
          padding: 10rem 16rem;
          background-color: $--clr-point-1;
          border-radius: 8rem;
          font-size: 13rem;
          font-weight: 700;
          line-height: 1.77;
          letter-spacing: normal;
          color: $--clr-white;
          &:hover {
            text-decoration: unset;
          }
        }
      }
      .btn_wrap {
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: auto;
        padding: 0;
        &:not(:first-child) {
          margin-top: 32rem;
        }
        & > * {
          &:not(:last-child) {
            margin-right: 5rem;
          }
          &:not(:first-child) {
            margin-left: 5rem;
          }
        }
      }
      .mxw {
        &-382 {
          max-width: 382rem;
          padding-left: 32rem;
          padding-right: 32rem;
        }
        &-604 {
          max-width: 604rem;
          padding-left: 80rem;
          padding-right: 80rem;
        }
      }
      .img {
        &.size {
          &-64 {
            width: 64rem;
            min-width: 64rem;
            height: 64rem;
            margin: 0 auto;
          }
          &-80 {
            width: 80rem;
            min-width: 80rem;
            height: 80rem;
            margin: 0 auto;
          }
        }
      }
      &.for_delivery {
        & > * {
          &:not(:nth-child(1)) {
            margin-top: 20rem;
          }
          &:nth-child(2n):not(:nth-child(4)) {
            max-width: calc(100% - 382rem);
            margin-right: 10rem;
          }
          &:nth-child(2n+1):not(:nth-child(1)) {
            max-width: 382rem;
            margin-left: 10rem;
          }
          &:nth-child(1) {
            flex: 2 1 100%;
            max-width: 100%;
            min-height: 280rem;
            padding: 48rem 0 48rem 48rem;
            & > .flex {
              align-items: center;
              .img_wrap {
                width: 100%;
              }
            }
            .addservice-text {
              &:not(:last-child) {
                margin-bottom: 32rem;
              }
            }
          }
          &:nth-child(4) {
            flex: 2 1 100%;
            max-width: 100%;
            min-height: 131rem;
            padding: 40rem 32rem;
            & > .flex {
              align-items: center;
              .addservice-title {
                margin-bottom: 0;
              }
              .btn {
                margin-left: auto;
              }
            }
          }
          .img_wrap {
            position: relative;
            display: inline-block;
            width: auto;
            height: auto;
            margin-top: 0;
            margin-left: 0;
            img {
              position: relative;
              display: inline-block;
              object-fit: contain;
              &.img-01 {
                width: 528rem;
                height: 330rem;
              }
              &.img-02 {
                width: 208rem;
                height: 134rem;
              }
            }
            & + .btn {
              margin-top: 16rem;
              float: right;
            }
          }
          .addservice-title {
            & + .small {
              margin-top: 10rem;
            }
          }
        }
      }
      &.for_taxi {
        & > * {
          &:nth-child(1) {
            padding: 48rem 0 0;
            & > .flex {
              align-items: center;
              &:before {
                order: 2;
                content: '';
                display: inline-block;
                width: 1px;
                min-width: 1px;
                height: 100%;
                max-height: 498rem;
                background-color: $--clr-lightgray-2;
              }
              .img_wrap {
                order: 1;
                width: 100%;
                text-align: center;
              }
              .flex.vert {
                width: 100%;
                min-width: 604rem;
                order: 3;
                justify-content: center;
                .flex {
                  height: auto;
                  & + .flex {
                    margin-top: 40rem;
                  }
                }
              }
            }
          }
          .img_wrap {
            position: relative;
            display: inline-block;
            width: auto;
            height: auto;
            margin-top: 0;
            margin-left: 0;
            img {
              position: relative;
              display: inline-block;
              object-fit: contain;
              &.img-01 {
                width: 260rem;
                height: 498rem;
                filter: drop-shadow(0 8px 12px rgba(0,0,0,0.16));
              }
            }
            & + .btn {
              margin-top: 16rem;
              float: right;
            }
          }
        }
      }
      &.for_shopping {
        & > * {
          text-align: center;
          &:nth-child(1) {
            padding: 48rem 0 0;
            & > .flex.vert {
              justify-content: center;
              align-items: center;
              & > .addservice-title {
                margin: 16rem 0 0;
                & + .addservice-title {
                  margin-top: 48rem;
                }
              }
              & > .flex {
                padding: 0 40rem;
                align-items: center;
                &:not(:first-child) {
                  margin-top: 60rem;
                }
                .img_wrap {
                  order: 1;
                  width: 100%;
                  text-align: center;
                  & + .img_wrap {
                    order: 3;
                    & + .img_wrap {
                      order: 5;
                    }
                  }
                }
                &:before, &:after {
                  content: '';
                  display: inline-block;
                  width: 1px;
                  min-width: 1px;
                  height: 100px;
                  max-height: 120rem;
                  margin: 0 20rem;
                  background-color: $--clr-lightgray-2;
                }
                &:before {
                  order: 2;
                }
                &:after {
                  order: 4;
                }
              }
              & > .block_box {
                display: inline-block;
                width: 100%;
                height: auto;
                padding: 48rem 40rem;
                &:not(:first-child) {
                  margin-top: 60rem;
                }
                .flex {
                  height: auto;
                  &:not(:first-child) {
                    margin-top: 48rem
                  }
                }
                .block_box {
                  .img_wrap {
                    width: 100%;
                    text-align: center;
                    &:not(:first-child) {
                      margin-top: 24rem;
                    }
                    img {
                      width: 320rem;
                      height: 180rem;
                    }
                  }
                  &.orange {
                    width: 100%;
                    margin-right: 10rem;
                    background-color: #FFF0D3;
                  }
                  &.blue {
                    width: 100%;
                    margin-left: 10rem;
                    background-color: #D7F5FF;
                  }
                }
              }
            }
          }
          .img_wrap {
            position: relative;
            display: inline-block;
            width: auto;
            height: auto;
            margin: 0;
            img {
              position: relative;
              display: inline-block;
              object-fit: contain;
            }
            &.vert {
              display: inline-flex;
              flex-direction: column;
              align-items: center;
              .addservice-text {
                &:not(:first-child) {
                  margin-top: 20rem;
                }
              }
            }
          }
        }
      }
      &.for_emall, &.for_direct, &.for_share, &.for_subsidy {
        & > * {
          text-align: center;
          &:nth-child(1) {
            padding: 48rem 0 0;
            .img_wrap {
              display: inline-block;
              width: 100%;
              height: auto;
              text-align: center;
              img {
                width: 640rem;
                height: 400rem;
                object-fit: contain;
              }
            }
          }
          &:nth-child(2) {
            padding: 48rem 40rem;
            & > .flex {
              padding: 0;
              align-items: center;
              &:not(:first-child) {
                margin-top: 60rem;
              }
              .img_wrap {
                order: 1;
                width: 100%;
                text-align: center;
                & + .img_wrap {
                  order: 3;
                  & + .img_wrap {
                    order: 5;
                  }
                }
              }
              &:before, &:after {
                content: '';
                display: inline-block;
                width: 1px;
                height: 100px;
                max-height: 120rem;
                margin: 0 20rem;
                background-color: $--clr-lightgray-2;
              }
              &:before {
                order: 2;
              }
              &:after {
                order: 4;
              }
            }
          }
          &:first-child:last-child {
            padding: 0 40rem;
            &.block_box {
              .img_wrap {
                & + .addservice-title {
                  margin-top: 64rem;
                }
              }
            }
          }
          .img_wrap {
            position: relative;
            display: inline-block;
            width: auto;
            height: auto;
            margin: 0;
            img {
              position: relative;
              display: inline-block;
              object-fit: contain;
              & + .addservice-text {
                margin-top: 20rem;
              }
            }
            &.vert {
              display: inline-flex;
              flex-direction: column;
              align-self: flex-start;
            }
          }
          .addservice-text {
            &:not(:first-child) {
              margin-top: 8rem;
            }
            &.small {
              line-height: 1.38;
            }
          }
        }
      }
      &.for_emall {
        & > .block_box {
          & > .btn_wrap {
            display: inline-flex;
            justify-content: center;
            width: 100%;
            height: auto;
            margin: 24rem 0 40rem;
            text-align: center;
            .btn {
              position: relative;
              width: auto;
              min-width: 120rem;
              height: 56px;
              padding: 18rem 24rem;
              font-size: 16rem;
              font-weight: 700;
              line-height: 1.25;
              letter-spacing: -0.48px;
            }
          }
        }
      }
      &.for_voting, &.benefitUser, &.benefitBusiness {
        & > * {
          &:not(:nth-child(1)) {
            margin-top: 20rem;
          }
          &:nth-child(2n):not(:nth-child(6)) {
            max-width: calc(50% - 10rem);
            margin-right: 10rem;
          }
          &:nth-child(2n+1):not(:nth-child(1)) {
            max-width: calc(50% - 10rem);
            margin-left: 10rem;
          }
          &:nth-child(1) {
            flex: 2 1 100%;
            max-width: 100%;
            min-height: 204rem;
            padding: 48rem 0;
            .addservice-title {
              text-align: center;
            }
          }
          &:nth-child(6) {
            flex: 2 1 100%;
            max-width: 100%;
            min-height: 131rem;
            padding: 40rem 32rem;
            & > .flex {
              align-items: center;
              .addservice-title {
                margin-bottom: 0;
              }
              .btn {
                margin-left: auto;
              }
            }
          }
          .img_wrap {
            position: relative;
            display: inline-block;
            width: auto;
            height: auto;
            margin-top: 0;
            margin-left: 0;
            &.vert {
              display: inline-flex;
              flex-direction: column;
              align-items: flex-start;
              & > * {
                text-align: left;
              }
            }
            img {
              position: relative;
              display: inline-block;
              object-fit: contain;
              margin-left: 0;
              &.img-01 {
                width: 528rem;
                height: 330rem;
              }
              &.img-02 {
                width: 208rem;
                height: 134rem;
              }
            }
            .addservice-text {
              &:not(:first-child) {
                margin-top: 16rem;
              }
            }
            & + .btn {
              margin-top: 16rem;
              float: right;
            }
          }
          .addservice-title {
            & + .small {
              margin-top: 10rem;
            }
          }
        }
      }
      &.benefitUser {
        & > * {
          margin: 0;
          &:nth-child(3n-2) {
            flex: 1 1 30%;
            width: 100%;
            height: 288rem;
            padding: 32rem 24rem;
            max-width: calc(33% - 15rem) !important;
            margin-top: 0;
            margin-right: 10rem;
          }
          &:nth-child(3n-1) {
            flex: 1 1 30%;
            width: 100%;
            height: 288rem;
            padding: 32rem 24rem;
            max-width: calc(33% - 10rem) !important;
            margin-top: 0;
            margin-left: 10rem;
            margin-right: 10rem;
          }
          &:nth-child(3n) {
            flex: 1 1 30%;
            width: 100%;
            height: 288rem;
            padding: 32rem 24rem;
            max-width: calc(33% - 15rem) !important;
            margin-top: 0;
            margin-left: 10rem;
          }
          &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
            margin-top: 20rem;
          }
          .text_wrap {
            display: flex;
            flex-direction: column;
            margin-top: 24rem;
            .addservice-text {
              display: block;
              word-break: keep-all;
              b {
                letter-spacing: -0.5px;
              }
            }
          }
          .img {
            & + .addservice-text {
              margin-top: 24rem !important;
            }
          }
        }
      }
      &.benefitBusiness {
        & > * {
          flex-direction: row;
          align-items: center;
          margin: 0;
          & > .img_wrap {
            .img {
              width: 152rem;
              height: 136rem;
            }
          }
          & > .text_wrap {
            margin-left: 32rem;
            .addservice-title {
              width: auto;
              text-align: left;
            }
          }
          &:nth-child(2n-1) {
            flex: 1 1 40%;
            width: 100%;
            height: 200rem;
            padding: 32rem 40rem;
            max-width: calc(50% - 10rem) !important;
            margin-top: 0;
            margin-left: 0 !important;
            margin-right: 10rem;
          }
          &:nth-child(2n) {
            flex: 1 1 40%;
            width: 100%;
            height: 200rem;
            padding: 32rem 40rem;
            max-width: calc(50% - 10rem) !important;
            margin-top: 0;
            margin-left: 10rem;
            margin-right: 0 !important;
          }
          &:not(:nth-child(1)):not(:nth-child(2)) {
            margin-top: 20rem;
          }
        }
      }
      &.issueMethod {
        .for_pc {
          display: block;
          &.flex {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 32rem;
          }
          &.img {
            display: block;
            width: 1024rem;
            min-width: 1024rem;
            height: 694rem;
            margin-top: -40rem;
            object-fit: contain;
          }
        }
        .for_mo {
          display: none;
          .img_wrap {
            height: 616rem;
            & > img {
              margin-bottom: 66rem;
            }
            & > .text_wrap {
              min-height: 128rem;
            }
          }
        }
        .img_wrap {
          position: relative;
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: 616rem;
          & > img {
            display: inline-block;
            width: 200rem;
            height: 418rem;
            margin-bottom: auto;
            border-radius: 27rem;
            box-shadow: 0 16rem 20rem rgba(0,0,0,0.08), 0 4px 4px rgba(0,0,0,0.08);
            object-fit: contain;
          }
          & > .text_wrap {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: auto;
            min-height: 122rem;
            text-align: center;
            & > .tag {
              margin-bottom: 24rem;
            }
            & > .addservice-text {
              text-align: center;
            }
          }
          & ~ img {
            &.arrow {
              width: 32rem;
              min-width: 32rem;
              height: 32rem;
              box-shadow: unset;
              border-radius: unset;
              margin-top: -200rem;
            }
          }
        }
        .issueSwiper {
          .swiper {
            &-slide {
              position: relative;
              width: 224rem;
              height: auto;
            }
            &-button {
              &-next {
                width: 32rem;
                min-width: 32rem;
                height: 32rem;
                right: 4px;
                transform: translate(0, -250%);
                background-image: var(--swiper-arr-r-g);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                &:before, &:after {
                  display: none;
                }
              }
              &-prev {
                width: 32rem;
                min-width: 32rem;
                height: 32rem;
                left: 4px;
                transform: translate(0, -250%);
                background-image: var(--swiper-arr-l-g);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                &:before, &:after {
                  display: none;
                }
              }
              &-disabled {
                opacity: 0;
              }
            }
          }
        }
      }
      &.for_about {
        flex-wrap: nowrap;
        flex-direction: column;
        & > * {
          flex: 1 1 100%;
          text-align: center;
          padding: 48rem 40rem;
          &:nth-child(1) {
            & > .flex {
              flex-wrap: wrap;
              align-items: center;
              padding: 0;
              &:not(:first-child) {
                margin-top: 60rem;
              }
              .img_wrap {
                order: 1;
                flex: 1 1 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                height: 274rem;
                margin: 0;
                padding: 48rem 0 54rem;
                text-align: center;
                & > * {
                  &:nth-child(1) {
                    margin-bottom: auto;
                  }
                }
                & + .img_wrap {
                  order: 3;
                  & + .img_wrap {
                    order: 5;
                  }
                }
              }
              &:before, &:after {
                content: '';
                display: inline-block;
                width: 1px;
                height: 100px;
                max-height: 120rem;
                margin: 0 20rem;
                background-color: $--clr-lightgray-2;
              }
              &:before {
                order: 2;
              }
              &:after {
                order: 4;
              }
            }
            & > .block_box {
              display: flex;
              align-items: center;
              height: 140rem;
              &:not(:first-child) {
                margin-top: 40rem;
                .addservice-title {
                  margin: auto 0;
                  text-align: left;
                  color: #F8B73D;
                }
                .img_wrap {
                  width: 120rem;
                }
              }
            }
          }
          &.tab-subtitle {
            display: inline-block;
            width: 100%;
            height: auto;
            min-height: unset;
            margin: 88rem 0 0;
            padding: 0;
            text-align: left;
          }
          &.block_box {
            display: inline-block;
            width: 100%;
            height: auto;
            padding: 48rem 40rem;
            &:not(:first-child) {
              margin-top: 60rem;
            }
            .flex {
              height: auto;
              &:not(:first-child) {
                margin-top: 48rem;
                .addservice-title {
                  &.big {
                    margin-bottom: 0;
                    & + .addservice-text {
                      margin-top: 8rem;
                    }
                  }
                }
                .addservice-text {
                  & + .addservice-text {
                    margin-top: 8rem;
                  }
                }
              }
            }
            .block_box {
              .img_wrap {
                width: 100%;
                text-align: center;
                &:not(:first-child) {
                  margin-top: 24rem;
                }
                img {
                  width: 320rem;
                  height: 180rem;
                }
              }
              &.orange {
                width: 100%;
                margin-right: 10rem;
                background-color: #FFF0D3;
              }
              &.blue {
                width: 100%;
                margin-left: 10rem;
                background-color: #D7F5FF;
              }
            }
          }
          .img_wrap {
            position: relative;
            display: inline-block;
            width: auto;
            height: auto;
            margin: 0;
            text-align: center;
            img {
              position: relative;
              display: inline-block;
              width: 100%;
              object-fit: contain;
              &.img {
                &-01 {
                  position: absolute;
                  width: 120rem !important;
                  height: 180rem !important;
                  right: 18rem;
                  bottom: -12rem;
                }
                &-02 {
                  height: 268rem;
                }
                &-03 {
                  height: 418rem;
                }
              }
            }
            &.vert {
              display: inline-flex;
              flex-direction: column;
              align-items: center;
              .addservice-title {
                &.max {
                  margin-bottom: 10rem;
                }
              }
              .addservice-text {
                &:not(:first-child) {
                  margin-top: 20rem;
                }
              }
            }
          }
        }
        &.usage {
          padding: 0;
          & > .flex {
            flex-wrap: wrap;
            padding: 0;
            & > .block_box {
              align-items: flex-start;
              width: 100%;
              height: auto;
              min-height: 592rem;
              padding: 48rem;
              &:nth-child(2n-1) {
                max-width: calc(50% - 12rem);
                margin-top: 0;
                margin-right: 12rem;
              }
              &:nth-child(2n) {
                max-width: calc(50% - 12rem);
                margin-top: 0;
                margin-left: 12rem;
              }
              &:not(:first-child) {
                .addservice-title {
                  color: $--clr-black;
                  text-align: center;
                }
              }
              .img_wrap {
                width: 100%;
                height: auto;
                text-align: center;
                img {
                  position: relative;
                  display: inline-block;
                  width: 360rem;
                  height: 280rem;
                  margin: 0;
                  right: unset;
                  bottom: unset;
                  &:not(:last-child) {
                    margin-bottom: 40rem;
                  }
                }
                .addservice-title {
                  &:not(:last-child) {
                    margin-bottom: 0;
                  }
                }
                .addservice-text {
                  word-break: keep-all;
                }
              }
            }
          }
        }
      }
    }
    &-title {
      position: relative;
      display: inline-block;
      width: 100%;
      height: auto;
      font-size: 20rem;
      font-weight: 700;
      line-height: 1.4;
      letter-spacing: -0.6px;
      color: $--clr-black;
      &.big {
        font-size: 24rem;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: -0.72px;
      }
      &.large {
        font-size: 28rem;
        font-weight: 400;
        line-height: 1.21;
        letter-spacing: -0.84px;
      }
      &.max {
        font-size: 36rem;
        font-weight: 800;
        line-height: normal;
        letter-spacing: normal;
      }
      &:not(:last-child) {
        margin-bottom: 16rem;
      }
    }
    &-ico {
      display: inline-block;
      width: 56rem;
      min-width: 56rem;
      height: 56rem;
      margin-right: 24rem;
      object-fit: contain;
    }
    &-text {
      display: inline-block;
      width: auto;
      height: auto;
      font-size: 16rem;
      font-weight: 400;
      line-height: 1.25;
      letter-spacing: normal;
      color: $--clr-gray-2;
      &.small {
        font-size: 13rem;
        line-height: 1.5;
      }
      &.f-clr {
        &-70 {
          color: $--clr-gray-4;
          b {
            color: $--clr-black;
          }
        }
      }
      b.f-clr-70 {
        color: $--clr-black;
      }
    }
  }

  // REGISTRY
  .registry {
    &-wrap {
      position: relative;
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;
      height: auto;
      &:not(:first-child) {
        margin-top: 72rem;
      }
      & > * {
        flex: 1 1 50%;
        width: 100%;
        min-height: 184rem;
        margin: 0;
        &:not(:nth-child(1)) {
          margin-top: 20rem;
        }
        &:nth-child(2n) {
          max-width: calc(50% - 10rem);
          margin-right: 10rem;
        }
        &:nth-child(2n+1):not(:nth-child(1)) {
          max-width: calc(50% - 10rem);
          margin-left: 10rem;
        }
        &:nth-child(1) {
          flex: 2 1 100%;
          max-width: 100%;
          height: 280rem;
          padding: 48rem !important;
          .registry-text {
            &:not(:last-child) {
              margin-bottom: 32rem;
            }
          }
          .img_wrap {
            position: relative;
            display: inline-block;
            width: auto;
            height: auto;
            img {
              position: relative;
              display: inline-block;
              object-fit: contain;
            }
            &:first-child {
              margin-top: 0 !important;
              margin-right: 56rem;
              img {
                width: 192rem;
                height: 173rem;
                transform: translate(0, 0);
              }
              & + div {
                margin-top: 0;
                display: inline-flex;
                flex-direction: column;
                align-items: flex-start;
              }
            }
            &:last-child {
              margin-top: 0 !important;
              margin-left: 10rem;
              img {
                width: 280rem;
                height: 230rem;
                transform: translate(0, 2px);
              }
            }
          }
        }
        &.block_box {
          display: inline-flex;
          flex-direction: column;
          padding: 32rem;
          & > div {
            & > *:not(:first-child) {
              margin-top: 4px;
            }
          }
        }
      }
      .btn {
        display: inline-block;
        width: auto;
        height: auto;
        margin: 0;
        padding: 10rem 16rem;
        background-color: $--clr-point-1;
        border-radius: 8rem;
        font-size: 13rem;
        font-weight: 700;
        line-height: 1.77;
        letter-spacing: normal;
        color: $--clr-white;
      }
    }
    &-title {
      position: relative;
      display: inline-flex;
      align-items: center;
      width: 100%;
      height: auto;
      font-size: 20rem;
      font-weight: 700;
      line-height: 1.4;
      letter-spacing: -0.6px;
      color: $--clr-black;
      &.big {
        display: inline-flex;
        align-items: center;
        width: 100%;
        height: auto;
        p {
          position: relative;
          display: inline-block;
          width: auto;
        }
        span {
          font-size: 28rem !important;
          font-weight: 700 !important;
          line-height: 1.21 !important;
          letter-spacing: -0.84px !important;
          color: $--clr-gray-2 !important;
        }
      }
      &:not(:last-child) {
        margin-bottom: 16rem;
      }
    }
    &-ico {
      display: inline-block;
      width: 32rem;
      min-width: 32rem;
      height: 32rem;
      margin-right: 8rem;
      object-fit: contain;
    }
    &-text {
      display: inline-block;
      width: auto;
      height: auto;
      font-size: 16rem;
      font-weight: 400;
      line-height: 1.25;
      letter-spacing: normal;
      color: $--clr-gray-2;
    }
  }

  // INQUIRY
  .inquiry {
    &-wrap {
      position: relative;
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;
      height: auto;
      & > * {
        flex: 1 1 30%;
        width: 100%;
        height: 184rem;
        margin: 0;
        &:not(:nth-child(1)):not(:nth-child(2)) {
          margin-top: 20rem;
        }
        &:nth-child(3n) {
          max-width: calc(33% - 15rem);
          margin-right: 10rem;
        }
        &:nth-child(3n+1):not(:nth-child(1)) {
          max-width: calc(33% - 10rem);
          margin-left: 10rem;
          margin-right: 10rem;
        }
        &:nth-child(3n+2):not(:nth-child(2)) {
          max-width: calc(33% - 15rem);
          margin-left: 10rem;
        }
        &:nth-child(1) {
          flex: 2 1 60%;
          max-width: calc(66% - 10rem);
          margin-right: 10rem;
        }
        &:nth-child(2) {
          flex: 1 1 30%;
          max-width: calc(33% - 10rem);
          margin-left: 10rem;
        }
        &.block_box {
          display: inline-flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 32rem 24rem;
          & > div {
            &:last-child {
              display: inline-block;
              width: 100%;
              text-align: right;
            }
            & > *:not(:first-child) {
              margin-top: 8rem;
            }
            span {
              display: inline-block;
              width: auto;
              height: auto;
              font-size: 16rem;
              font-weight: 400;
              line-height: 1.25;
              letter-spacing: normal;
              color: $--clr-gray-2;
            }
            a, button {
              display: inline-block;
              width: auto;
              height: auto;
              font-size: 16rem;
              font-weight: 400;
              line-height: 1.25;
              letter-spacing: normal;
              color: $--clr-black;
              &:hover {
                text-decoration: underline;
              }
              &.big {
                margin-top: 0;
                font-size: 36rem;
                font-weight: 500;
                line-height: 1.17;
                letter-spacing: -1.08px;
                &:not(:first-child) {
                  margin-left: 24rem;
                }
              }
              &.btn {
                display: inline-block;
                width: auto;
                height: auto;
                margin: 0;
                padding: 10rem 16rem;
                background-color: $--clr-point-1;
                border-radius: 8rem;
                font-size: 13rem;
                font-weight: 700;
                line-height: 1.77;
                letter-spacing: normal;
                color: $--clr-white;
                &:hover {
                  text-decoration: unset;
                }
              }
            }
          }
        }
        &.img_wrap {
          position: relative;
          display: inline-flex;
          justify-content: center;
          align-items: flex-end;
          img {
            position: relative;
            display: inline-block;
            width: 217rem;
            height: 204rem;
            object-fit: contain;
            transform: translate(0, 20rem);
          }
        }
      }
    }
    &-title {
      display: inline-flex;
      align-items: center;
      width: 100%;
      height: auto;
      font-size: 20rem;
      font-weight: 700;
      line-height: 1.4;
      letter-spacing: -0.6px;
      color: $--clr-black;
    }
    &-ico {
      display: inline-block;
      width: 32rem;
      min-width: 32rem;
      height: 32rem;
      margin-right: 8rem;
      object-fit: contain;
    }
    &-text {
      display: inline-block;
      width: auto;
      height: auto;
      font-size: 16rem;
      font-weight: 400;
      line-height: 1.25;
      letter-spacing: normal;
      color: $--clr-gray-2;
    }
  }

  // BI
  .bi {
    &-wrap {
      position: relative;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0 50rem;
      &:not(:first-child) {
        margin-top: 64rem;
      }
      & > * {
        &:not(:first-child) {
          margin-top: 64rem;
        }
        &.img_wrap {
          position: relative;
          display: inline-block;
          width: 100%;
          height: auto;
          margin: 0;
          padding: 0;
          text-align: center;
          img {
            display: inline-block;
            width: 260rem;
            height: 210rem;
            object-fit: contain;
          }
        }
        &.wrap {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          height: auto;
          & > .flex {
            & > .img_wrap {
              position: relative;
              display: inline-flex;
              flex-direction: column;
            }
          }
          & > .img_wrap {
            position: relative;
            display: inline-flex;
            align-items: center;
          }
          .tag {
            &:not(:last-child) {
              margin-bottom: 24rem;
            }
          }
        }
      }
      .btn {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: auto;
        padding: 18rem 24rem;
        font-size: 16rem;
        font-weight: 700;
        line-height: 1.25;
        letter-spacing: -0.48px;
        border-radius: 8rem;
        text-align: center;
        word-break: keep-all;
        &_wrap {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: auto;
          padding: 0;
          & > * {
            &:not(:last-child) {
              margin-right: 5rem;
            }
            &:not(:first-child) {
              margin-left: 5rem;
            }
          }
        }
        &.line {
          background-color: $--clr-white;
          color: $--clr-black;
          border: 1px solid $--clr-lightgray-1;
        }
        &.bg-point {
          background-color: $--clr-point-1;
          color: $--clr-white;
        }
      }
    }
    &-text {
      display: inline-block;
      width: 100%;
      height: auto;
      font-size: 16rem;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: normal;
      color: $--clr-gray-2;
      text-align: center;
      word-break: keep-all;
      &.caption {
        font-size: 13rem;
        font-weight: 400;
        line-height: 1.77;
        &:not(:first-child) {
          margin-top: 16rem;
        }
      }
    }
    &-img {
      position: relative;
      display: inline-block;
      width: auto;
      height: auto;
      object-fit: contain;
      z-index: 1;
      &.ci {
        &-1 {
          position: absolute;
          display: inline-block;
          width: 230rem;
          height: 106rem;
          left: 50%;
          transform: translate(-50%, 0);
        }
        &-2 {
          position: absolute;
          display: inline-block;
          width: 220rem;
          height: 80rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, calc(-50% - 20rem));
        }
        &-3 {
          position: absolute;
          display: inline-block;
          width: 330rem;
          height: 60rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, calc(-50% - 30rem));
        }
      }
      &.bg, &.bg-1, &.bg-2 {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 196rem;
        left: 0;
        object-fit: cover;
        overflow: hidden;
        z-index: 0;
      }
      &.bg-1 {
        object-position: left;
      }
      &.bg-2 {
        object-position: right;
      }
    }
  }

  // popup
  .popup {
    position: fixed;
    display: inline-block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 997;
    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      background-color: $--clr-black;
      opacity: 0.3;
    }
    &-wrap {
      position: relative;
      display: block;
      //width: min(320rem, calc(100vw - 40rem));
      width: min(480rem, calc(100vw - 40rem));
      height: auto;
      margin: 0;
      padding: 0;
      top: 50%;
      left: 50%;
      background-color: $--clr-white;
      border-radius: 16rem;
      box-shadow: 0 8px 8px rgba(0,0,0,0.16);
      transform: translate(-50%, -50%);
      z-index: 998;
      overflow: hidden;
    }
    &-head {
      display: none;
    }
    &-body {
      position: relative;
      display: inline-flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      //max-height: min(436rem, calc(100vw - 40rem));
      max-height: min(570rem, calc(100vh - 40rem));
      padding: 0 0 42rem;
    }
    &-contents {
      position: relative;
      display: inline-block;
      flex: 1;
      width: 100%;
      height: 100%;
      //max-height: min(380rem, calc(100vh - 96rem));
      max-height: min(514rem, calc(100vh - 96rem));
      margin: 0;
      overflow-x: hidden;
      overflow-y: auto;
      & > h3 {
        display: none;
        width: 100%;
        height: auto;
        font-size: 16rem;
        font-weight: 700;
        line-height: 1.63;
        word-break: break-word;
        text-align: center;
        color: $--clr-black;
        &:not(:last-child) {
          margin-bottom: 8rem;
        }
      }
      & > p {
        font-size: 16rem;
        font-weight: 400;
        line-height: 1.63;
        letter-spacing: normal;
        word-break: break-word;
        text-align: center;
        color: $--clr-black;
        & > * {
          &:not(a) {
            padding: 24rem 20rem;
          }
        }
        & > p {
          &:first-child:last-child {
            padding: 0;
          }
        }
        img {
          width: 100% !important;
          height: auto !important;
        }
      }
      & > hr {
        display: inline-block;
        width: calc(100% + 60rem);
        height: 1px;
        margin: 30rem 0 30rem -30rem;
        background-color: $--clr-gray-4;
        border: 0;
      }
      & > figure {
        position: relative;
        width: 100%;
        height: auto;
        margin: 0 0 30rem;
        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
        &:last-child {
          margin: 0;
        }
      }
      a {
        display: block;
      }
    }
    &-btm {
      position: absolute;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 56rem;
      left: 0;
      bottom: 0;
      padding: 17rem 20rem;
      background-color: $--clr-white;
      border-radius: 0 0 16rem 16rem;
      * {
        color: $--clr-black;
      }
      .btn {
        position: relative;
        display: inline-flex;
        align-items: center;
        font-size: 14rem;
        font-weight: 400;
        line-height: 1.57;
        letter-spacing: -0.21px;
        color: $--clr-black;
        cursor: pointer;
        &:last-child {
          margin-left: auto;
          font-weight: 700;
          text-align: right;
          letter-spacing: normal;
          &:before {
            content: '';
            position: relative;
            display: inline-block;
            width: 20rem;
            min-width: 20rem;
            height: 20rem;
            margin-right: 6rem;
            background-image: var(--btn-pop-close);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  // agree
  .agree {
    &-container {
      position: relative;
      display: inline-block;
      width: 100%;
      max-width: 1024rem;
      height: auto;
      margin: 60rem auto;
      & > .flex {
        justify-content: flex-end;
        width: 100%;
        height: auto;
        margin: 40rem 0;
        .select-wrap {
          width: 260rem;
          z-index: 1;
          .btn-select, .option-list {
            width: 260rem;
            max-width: 260rem;
          }
        }
      }
    }
    &-wrap {
      position: relative;
      display: inline-block;
      width: 100%;
      max-width: 1024rem;
      height: auto;
      margin: 0 auto;
      h5 {
        display: inline-block;
        width: 100%;
        font-size: 24rem;
        font-weight: 700;
        line-height: 1.33;
        letter-spacing: -0.2px;
        color: $--clr-black;
        word-break: keep-all;
        text-align: left;
      }
      p {
        display: inline-block;
        width: 100%;
        font-size: 18rem;
        font-weight: 400;
        line-height: 1.78;
        letter-spacing: -0.2px;
        color: $--clr-black;
        word-break: break-all;
        text-align: left;
        & > a {
          display: block;
        }
      }
      br {
        line-height: 1;
      }
      table {
        position: relative;
        display: inline-table;
        table-layout: auto;
        width: 100%;
        height: auto;
        border-top: 1px solid $--clr-black;
        tr {
          th {
            display: table-cell;
            width: auto;
            padding: 20rem;
            font-size: 16rem;
            font-weight: 700;
            line-height: 1.63;
            letter-spacing: normal;
            color: $--clr-black;
            word-break: break-all;
            text-align: center;
            background-color: $--clr-lightgray-3;
            border-bottom: 1px solid $--clr-gray-4;
            border-left: 1px solid $--clr-gray-4;
            border-right: 1px solid $--clr-gray-4;
            &:first-child {
              border-left: 0;
            }
            &:last-child {
              border-right: 0;
            }
          }
          td {
            display: table-cell;
            width: auto;
            padding: 15rem;
            font-size: 16rem;
            font-weight: 400;
            line-height: 1.63;
            letter-spacing: normal;
            color: $--clr-black;
            word-break: break-all;
            text-align: center;
            background-color: $--clr-white;
            border-bottom: 1px solid $--clr-gray-4;
            border-left: 1px solid $--clr-gray-4;
            border-right: 1px solid $--clr-gray-4;
            &:first-child {
              border-left: 0;
            }
            &:last-child {
              border-right: 0;
            }
          }
          p {
            font-size: 16rem;
            font-weight: 400;
            line-height: 1.625;
            letter-spacing: normal;
          }
        }
      }
    }
    &-title {
      display: inline-block;
      width: 100%;
      font-size: 40rem;
      font-weight: 800;
      line-height: 1.2;
      color: $--clr-black;
      text-align: center;
    }
  }

  // 미디어쿼리 @media
  @import './layout/components_media';