// DIV FRAME
.flex {
    display: flex;
    width: 100%;
    height: 100%;
    &.vert {
        flex-direction: column;
    }
    &.center {
        justify-content: center;
        align-items: center;
    }
    &.spaceBtw {
        justify-content: space-between;
        align-items: center;
    }
    &.align {
        &-start {
            justify-content: flex-start;
        }
        &-end {
            justify-content: flex-end;
        }
    }
    &.wrap {
        flex-wrap: wrap;
    }
    .wrap {
        display: block;
        width: auto;
        height: auto;
        margin: auto;
    }
}

// TEXT ALIGN
.txt-align {
    &.left {
        text-align: left !important;
        .flex {
            span {
                text-align: left;
                //margin-right: auto;
            }
        }
    }
    &.center {
        text-align: center !important;
        .flex {
            span {
                text-align: center;
                //margin: 0 auto;
            }
        }
    }
    &.right {
        text-align: right !important;
        .flex {
            span {
                text-align: right;
                //margin-left: auto;
            }
        }
    }
    &.left, &.center, &.right {
        .flex {
            span {
                width: 100%;
                margin: 0 auto;
            }
        }
        &.wd85 {
            .flex {
                span {
                    max-width: 100px;
                    white-space: normal;
                }
            }
        }
        &.wd110 {
            .flex {
                span {
                    max-width: 110px;
                    white-space: normal;
                }
            }
        }
        &.wd160 {
            .flex {
                span {
                    max-width: 160px;
                    white-space: normal;
                }
            }
        }
    }
}

// MO SET
@media screen and (max-width: 1023px) {
}

