// 색상
$--clr-black: #000;
$--clr-black-trans: rgba(0,0,0,0);
$--clr-white: #fff;
$--clr-white-trans: rgba(255,255,255,0);
$--clr-gray-1: #303030;
$--clr-gray-2: #333;
$--clr-gray-3: #4d4d4d;
$--clr-gray-4: #707070;
$--clr-gray-5: #777;
$--clr-lightgray-1: #e0e0e0;
$--clr-lightgray-2: #e6e6e6;
$--clr-lightgray-3: #eee;
$--clr-lightgray-4: #ebeff5;
$--clr-point-1: #f8b73d;
$--clr-point-2: #31a3c9;
$--clr-point-3: #31a3ca;
$--clr-point-bg: #fafcff;

:root{
  // swiper
  --swiper-scrollbar-sides-offset: 0px;
  --swiper-scrollbar-drag-bg-color: #828282;

  // 공통 아이콘/이미지
  --logo-incheon: url("/image/logo/incheon.svg");
  --logo-incheon-g: url("/image/logo/incheon_g.svg");

  --btn-hamburger-b: url("/image/icon/hamburger_b.svg");
  --btn-hamburger-close: url("/image/icon/hamburger_close.svg");

  --btn-search-g: url("/image/icon/search_g.svg");
  --btn-pop-close: url("/image/icon/ic_close.svg");
  --btn-plus: url("/image/icon/plus_icon.svg");

  --btn-page-arr-lt: url("/image/icon/page_arr_left.svg");
  --btn-page-arr-rt: url("/image/icon/page_arr_right.svg");

  --icon-arr-up-b: url("/image/icon/arr_up_b.svg");
  --icon-arr-dn-b: url("/image/icon/arr_down_b.svg");
  --icon-arr-dn-w: url("/image/icon/arr_down_w.svg");
  --icon-arr-dn-g: url("/image/icon/arr_down_g.svg");

  --icon-sel-arr: url("/image/icon/select_arrow.svg");

  --icon-attachment: url("/image/icon/ic_clip_24.svg");
  --icon-pin: url("/image/icon/icon_pin.svg");
  --icon-faq-q: url("/image/icon/q_icon.svg");
  --icon-faq-q-on: url("/image/icon/q_icon_on.svg");
  --icon-faq-a: url("/image/icon/a_icon.svg");

  --icon-nolist: url("/image/img/store.png");
  --icon-preparing: url("/image/img/store2.png");

  --swiper-arr-r-g: url("/image/img/next_icon.png");
  --swiper-arr-l-g: url("/image/img/back_icon.png");

  --swiper-arr-r-circle: url("/image/icon/swiper_arr_r_circle.png");
  --swiper-arr-l-circle: url("/image/icon/swiper_arr_l_circle.png");
}
