.payment-wrap {
  margin: 32rem 0 0;
  .location_cont {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 16rem;
    .location_tit {
      font-size: 13rem;
      font-weight: 400;
      line-height: 22rem;
      display: block;
      position: relative;
      text-align: right;
      padding-left: 18rem;
      word-break: break-word;
      &::before {
        content: '';
        width: 13rem;
        height: 13rem;
        display: inline-block;
        background-image: url('/image/icon/location_icon_01.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }
      strong {
        font-weight: 700;
      }
    }
    button {
      margin-left: 8rem;
      font-size: 0;
      width: 24rem;
      height: 24rem;
      background-image: url('/image/icon/location_btn_01.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .search_cont {
    display: flex;
    align-items: center;
    padding: 20rem 24rem;
    border-radius: 16rem;
    background-color: $--clr-white;
    box-shadow: 0 4px 12rem 0 rgba(0,0,0,0.08);
    .search_inner {
      flex: 1;
      .search_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        & + .search_box {
          margin-top: 8rem;
        }
        .box {
          display: flex;
          align-items: center;
          & + .box {
            margin-left: 32rem;
          }
          &:first-child {
            h2 {
              flex: 0;
            }
          }
          h2 {
            font-size: 13rem;
            font-weight: 400;
            line-height: 23rem;
            margin-right: 16rem;
            white-space: nowrap;
          }
          input {
            height: 40rem;
            border-radius: 4px;
            border: 1px solid $--clr-lightgray-2;
            padding: 9rem 10rem;
            font-size: 13rem;
            width: 160rem;
            max-width: 160rem;
          }
          label {
            &+label {
              margin-left: 8rem;
            }
            & > select {
              width: 160rem;
              max-width: 160rem;
              height: 40rem;
              border-radius: 4px;
              border: 1px solid $--clr-lightgray-2;
              padding: 9rem 20rem 9rem 10rem;
              font-size: 13rem;
              font-weight: 400;
              appearance: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              background-color: $--clr-white;
              background-image: var(--icon-sel-arr);
              background-repeat: no-repeat;
              background-position: right 10rem center;
              background-size: 14rem;
            }
          }
          .block_check-wrap {
            display: flex;
            align-items: center;
            & + .block_check-wrap {
              margin-left: 8rem;
            }
            label {
              min-width: 150rem;
              display: flex;
              .bar {
                font-size: 13rem;
                font-weight: 400;
                line-height: 23rem;
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                padding: 8rem 0 8rem 33rem;
                &::before {
                  content: '';
                  display: inline-block;
                  position: absolute;
                  box-sizing: border-box;
                  width: 24rem;
                  height: 24rem;
                  background-image: url(/image/icon/checkbox_off.svg);
                  background-repeat: no-repeat;
                  background-position: center center;
                  border: 0;
                  background-size: contain;
                  left: 0;
                  top: 50%;
                  transform: translate(0, -50%);
                }
              }
              input {
                opacity: 0;
                width: 0;
                height: 0;
                position: absolute;
                overflow: hidden;
                -webkit-appearance: none;
              }
              & > input:checked+span::before {
                content: '';
                display: inline-block;
                position: absolute;
                box-sizing: border-box;
                width: 24rem;
                height: 24rem;
                background-image: url(/image/icon/checkbox_on.svg);
                background-repeat: no-repeat;
                background-position: center center;
                border: 0;
                background-size: contain;
                left: 0;
                top: 50%;
                transform: translate(0, -50%);
              }
            }
          }
        }
      }
    }
    .search-btn {
      flex: 0 0 auto;
      .search-elem {
        & > .btn-search {
          height: 40rem;
          font-size: 13rem;
          font-weight: 700;
          color: #fff;
          border-radius: 4px;
          background-color: #f8b73d;
          padding: 9rem 24rem;
        }
      }
    }
  }

  .payment_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20rem;
    li {
      padding: 24rem;
      border-radius: 16rem;
      background: $--clr-white;
      box-shadow: 0 4px 12rem 0 rgba(0,0,0,0.08);
      text-align: left;
      & > .box {
        display: flex;
        flex-direction: column;
        height: 171rem;
        //justify-content: space-between;
        //gap: 16rem;
        .sub {
          display: flex;
          justify-content: flex-start;
          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
          //gap: 6rem;
          & > div {
            height: auto;
            min-height: 30rem;
            max-height: 58rem;
            text-align: left;
            span{
              display: inline-block;
              margin-right: 6rem;
              margin-bottom: 8rem;
              font-size: 12rem;
              font-weight: 700;
              line-height: 18rem;
              padding: 2rem 8rem;
              border-radius: 12rem;
              &.jeonsi{
                color: #FF364B;
                background-color: #FFEDED;
              }
            }
            &.jeonsi{
              color: #FF364B;
              background-color: #FFEDED;
            }
            &.tag{
            }
          }
        }
        .title {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          height: auto;
          &:not(:first-child) {
            margin-top: 16rem;
          }
          & > * {
            position: relative;
            display: block;
            max-width: 100%;
            height: auto;
            max-height: 22rem;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          p {
            color: #999999;
            font-size: 13rem;
            font-weight: 400;
            line-height: 23rem;
            text-align: left;
          }
          h2 {
            font-size: 16rem;
            font-weight: 700;
            line-height: 20rem;
            letter-spacing: -0.48rem;
            text-align: left;
          }
          span {
            max-height: 44rem;
            color: #333333;
            font-size: 13rem;
            font-weight: 400;
            line-height: 23rem; /* 176.923% */
            text-align: left;
          }
        }
        .call {
          display: flex;
          justify-content: space-between;
          margin-top: auto;
          & > div, & > a {
            position: relative;
            margin-top: 16rem;
            font-size: 13rem;
            font-weight: 400;
            line-height: 23rem;
            &:first-child{
              color: #333333;
              padding-left: 18rem;
              pointer-events: auto;
              cursor: pointer;
              &::before{
                content: '';
                width: 14rem;
                height: 14rem;
                display: inline-block;
                background-image: url(/image/icon/icon_call.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                position: absolute;
                top: 50%;
                left: 0;
                -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
              }
            }
            &:last-child{
              color: #777777;
              padding-left: 18rem;
              &:first-child {
                margin-left: auto;
              }
              &::before{
                content: '';
                width: 14rem;
                height: 14rem;
                display: inline-block;
                background-image: url(/image/icon/icon_local.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                position: absolute;
                top: 50%;
                left: 0;
                -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
              }
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 1023px) {
  .payment-wrap {
    .location_cont {
      justify-content: flex-start;
      .location_tit {
        text-align: left;
        padding-left: 25rem;
      }
    }
    .search_cont {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      box-shadow: 0 4px 12rem 0 rgba(0,0,0,0.08);
      .search_inner {
        position: relative;
        display: inline-block;
        width: 100%;
        flex: 1;
        &:not(:last-child) {
          margin-bottom: 24rem;
        }
        .search_box {
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          & > .box {
            align-items: flex-start;
            width: 100%;
            margin: 0 !important;
            & + .box {
              margin-top: 8rem !important;
            }
            & > label, & > input, & > .flex {
              width: 100%;
              max-width: 100%;
            }
            h2 {
              min-width: 60rem;
              height: 40rem;
              line-height: 40rem;
            }
            label {
              select {
                width: 100%;
                max-width: 100%;
              }
              & + label {
                margin-top: 8rem;
                margin-left: 0;
              }
            }
            .block_check-wrap {
              & + .block_check-wrap {
                margin-top: 0;
                margin-left: 0;
              }
            }
            .flex {
              flex-direction: column;
            }
          }
        }
      }
    }
    .payment_list {
      grid-template-columns: repeat(1, 1fr);
      li {
        & > .box {
          height: auto;
          .sub {
            & > div {
              max-height: unset;
            }
          }
          .title {
            span {
              max-height: unset;
            }
          }
        }
      }
    }
  }
}