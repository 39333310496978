.frame {
  &-container {
    &.for_main {
      padding: 0 24rem;
      overflow-x: hidden;
      .frame-contents__inner {
        padding: 0;
        &:first-child {
          margin-bottom: 16px;
        }
        &.mainMenu {
          & > .flex {
            justify-content: center;
            align-items: flex-start;
            & > div {
              position: relative;
              display: inline-block;
              width: 104px;
              height: auto;
              min-height: 100px;
              & > a {
                position: absolute;
                display: inline-block;
                width: 100%;
                height: 100%;
                text-align: center;
                img {
                  position: relative;
                  display: inline-block;
                  width: 56px;
                  height: 56px;
                  object-fit: contain;
                }
                p {
                  position: relative;
                  display: inline-block;
                  width: 100%;
                  font-size: 13rem;
                  font-weight: 400;
                  line-height: 1.38;
                  color: $--clr-black;
                  word-break: keep-all;
                  &:not(:first-child) {
                    margin-top: 8px;
                  }
                }
              }
            }
          }
        }
        &.gridMenu {
          position: relative;
          margin-bottom: 60px;
          & > .flex {
            position: relative;
            display: inline-flex;
            width: 100%;
            flex-wrap: wrap;
            & > .block_box {
              position: relative;
              width: auto;
              height: 184px;
              overflow: hidden;
              &:nth-child(1) {
                flex: 2 1 calc(65% - 10px);
                &:not(:last-child) {
                  margin-right: 10px;
                }
              }
              &:nth-child(2) {
                flex: 1 1 calc(32% - 10px);
                &:not(:first-child) {
                  margin-left: 10px;
                }
              }
              &:not(:nth-child(1)):not(:nth-child(2)) {
                flex: 1 1 calc(32% - 10px);
                margin-top: 20px;
                &:not(:nth-child(3)):not(:last-child) {
                  margin-left: 10px;
                  margin-right: 10px;
                }
                &:nth-child(3) {
                  margin-right: 10px;
                }
                &:last-child {
                  margin-left: 10px;
                }
              }
              & > a {
                position: relative;
                &:first-child:last-child {
                  width: 100%;
                  height: 100%;
                  img {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }
              & > .flex {
                align-items: center;
                height: auto;
                min-height: 84px;
                & > .img_wrap {
                  width: 64px;
                  min-width: 64px;
                  height: 64px;
                  //padding: 8px;
                  //border: 1px solid $--clr-lightgray-1;
                  //border-radius: 8px;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                }
                & > .text_wrap {
                  &.with_line {
                    display: flex;
                    align-items: center;
                    &:before {
                      content: '';
                      position: relative;
                      display: inline-block;
                      width: 1px;
                      height: 40px;
                      margin: 0 24px;
                      background-color: $--clr-lightgray-2;
                    }
                  }
                }
              }
              .box {
                &-text {
                  font-size: 13rem;
                  font-weight: 400;
                  line-height: normal;
                  color: #375C72;
                }
                &-subtitle {
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 1.25;
                  color: #000;
                  &:not(:last-child) {
                    margin-bottom: 16px;
                    & + .box-text {
                      margin-top: -12px;
                    }
                  }
                }
                &-title {
                  font-size: 28px;
                  font-weight: 500;
                  line-height: normal;
                  color: #313A40;
                }
              }
              .linkTo {
                &-emall {
                  position: absolute;
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  background-color: #B8E9FE;
                  img {
                    object-fit: contain !important;
                  }
                }
                &-icon {
                  position: relative;
                  display: inline-flex;
                  flex-direction: column;
                  align-items: center;
                  width: auto;
                  height: auto;
                  img {
                    width: 40px;
                    height: 40px;
                    min-height: 40px;
                    object-fit: contain;
                  }
                  .box-text {
                    margin-top: 12px;
                    font-size: 11px;
                    font-weight: 400;
                    line-height: normal;
                    color: $--clr-gray-1;
                  }
                }
              }
            }
            .tab-box {
              display: inline-block;
              width: 100%;
              height: auto;
              .tab {
                &-wrap {
                  width: 100%;
                  height: auto;
                  max-height: unset;
                  margin-bottom: 18px;
                  border: 0;
                  border-bottom: 0;
                  &:before {
                    display: none;
                  }
                  &__inner {
                    margin: 0;
                  }
                }
                &-elem {
                  position: relative;
                  width: auto;
                  height: auto;
                }
                &-label {
                  position: relative;
                  width: auto;
                  height: auto;
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 1.25;
                  letter-spacing: -0.48px;
                  color: $--clr-gray-5;
                  transition: color 0.3s ease-in-out;
                  &:before {
                    content: '';
                    position: absolute;
                    display: inline-block;
                    width: 0;
                    height: 6px;
                    bottom: 0;
                    border-radius: 6px;
                    background-color: $--clr-white-trans;
                    z-index: -1;
                    transition: all 0.3s ease-in-out;
                  }
                  &:after {
                    display: none;
                  }
                  &[aria-pressed='true'], &[aria-pressed="true"] {
                    color: $--clr-black;
                    &:before {
                      width: 100%;
                      background-color: #F9BF56;
                    }
                  }
                }
                &-contents {
                  &__inner {
                    max-width: unset;
                    padding: 0;
                  }
                }
              }
            }
            .btn {
              &.see-more {
                display: inline-flex;
                align-items: center;
                font-size: 13rem;
                font-weight: 400;
                line-height: 1.77;
                color: $--clr-gray-5;
                &:not(:first-child) {
                  margin-left: auto;
                }
                &:hover {
                  text-decoration: underline;
                }
                &:before {
                  content: '';
                  position: relative;
                  display: inline-block;
                  width: 13rem;
                  height: 13rem;
                  margin-right: 6px;
                  background-image: var(--btn-plus);
                  background-size: contain;
                  background-position: center;
                  background-repeat: no-repeat;
                }
              }
            }
            .link-wrap {
              position: relative;
              display: inline-block;
              width: auto;
              .link-list {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                width: auto;
                height: auto;
                padding: 0 0 0 11px;
                font-size: 13rem;
                font-weight: 400;
                line-height: normal;
                color: $--clr-gray-2;
                word-break: break-all;
                overflow: hidden;
                cursor: pointer;
                transition: color 0.15s ease-in-out;
                &:before {
                  content: '';
                  position: absolute;
                  display: inline-block;
                  width: 3px;
                  height: 3px;
                  border-radius: 3px;
                  margin: 7px 0 0 -11px;
                  background-color: $--clr-gray-2;
                }
                &:hover {
                  color: $--clr-point-1;
                }
                & + .link-list {
                  margin-top: 7px;
                }
              }
            }
            .youtube-link {
              position: absolute !important;
              display: inline-flex;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              border-radius: 16px;
              overflow: hidden;
              &:before {
                content: '';
                position: absolute;
                display: inline-block;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(0,0,0,0.36);
                z-index: 1;
              }
              &:after {
                content: '';
                position: absolute;
                display: inline-block;
                width: 112px;
                height: 88px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-image: url('/image/img/youtube_img.png');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                z-index: 2;
              }
              .img {
                &.youtube {
                  width: 100%;
                  height: 100%;
                  border-radius: 16px;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
  }
}
.mainSwiper {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: visible;
  //&:before, &:after {
  //  content: '';
  //  position: absolute;
  //  display: inline-block;
  //  width: calc((100vw - 1024px)/2);
  //  height: 100%;
  //  margin: 0;
  //  top: 0;
  //  z-index: 3;
  //}
  //&:before {
  //  left: 0;
  //  background: linear-gradient(90deg, rgba(250,252,255,1)  0%, rgba(250,252,255,0.5) 100%);
  //  transform: translate(-100%, 0);
  //}
  //&:after {
  //  right: 0;
  //  background: linear-gradient(270deg, rgba(250,252,255,1)  0%, rgba(250,252,255,0.5) 100%);
  //  transform: translate(100%, 0);
  //}
  .text_wrap {
    max-width: 60%;
    max-height: 270px;
    padding-top: 68px;
    pointer-events: none;
    & > * {
      pointer-events: none;
      &:first-child {
        font-family: 'JalnanOTF', sans-serif;
        font-size: 42px;
        font-weight: 700;
        line-height: 1.24;
        color: $--clr-black;
      }
      &:not(:first-child) {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.25;
        color: $--clr-gray-2;
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.25;
          color: $--clr-gray-2;
        }
      }
    }
  }
  .img_wrap {
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 340px;
    left: 0;
    bottom: 0;
    border-radius: 16px;
    background-color: $--clr-white;
    pointer-events: none;
    z-index: -1;
    & > a {
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: auto;
      z-index: 1;
    }
    img {
      position: absolute;
      display: inline-block;
      width: 360px;
      height: 360px;
      top: -68px;
      right: 76px;
      object-fit: contain;
      pointer-events: none;
    }
  }
  .swiper {
    //overflow: visible;
    &-wrapper {
      width: 100%;
      height: 408px;
    }
    &-slide {
      padding: 70px 100px;
      cursor: default;
      mask-image: unset;
      //transition: mask-image 0.5s ease-in-out;
      //&:not(.swiper-slide-active) {
      //  mask-image: linear-gradient(0deg, $--clr-white-trans 0%, $--clr-white-trans 100%);
      //  pointer-events: none !important;
      //  cursor: default !important;
      //}
      //&-prev {
      //  mask-image: linear-gradient(90deg, $--clr-white-trans 60%, rgba(250,252,255,0.5) 100%) !important;
      //}
      //&-next {
      //  mask-image: linear-gradient(270deg, $--clr-white-trans 60%, rgba(250,252,255,0.5) 100%) !important;
      //}
      a {
        pointer-events: none;
        cursor: default;
        &[href] {
          pointer-events: auto;
          cursor: pointer;
        }
      }
    }
    &-button {
      &-prev {
        width: 54px;
        height: 58px;
        margin-top: -34px;
        margin-left: -36px;
        background-image: var(--swiper-arr-l-circle);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        &:before, &:after {
          display: none;
        }
      }
      &-next {
        width: 54px;
        height: 58px;
        margin-top: -34px;
        margin-right: -36px;
        background-image: var(--swiper-arr-r-circle);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        &:before, &:after {
          display: none;
        }
      }
    }
    &-pagination {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      text-align: center;
      &:not(:first-child) {
        margin-top: 32px;
      }
      &-bullet {
        width: 6px;
        height: 6px;
        border-radius: 6px;
        margin: 0 3px;
        background-color: $--clr-lightgray-1;
        opacity: 1;
        transition: all 0.2s ease-in-out;
        &-active {
          width: 16px;
          background-color: $--clr-point-1;
          opacity: 1;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
}

// 미디어쿼리 @media
@import './pages/main_media';